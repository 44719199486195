import { useState } from "react";

import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";

import DialogPlaceHolder from "components/Dialog/DialogPlaceHolder";
import FilterContainer from "components/Filter/FilterContainer";
import useTranslations from "hooks/useTranslations";
import { IFilter } from "model/application/Filter";

import PictureGalleryList from "./PictureGalleryList";
import PictureGallerySlide from "./PictureGallerySlide";

export interface IPictureGalleryProps {
  isLoadingCustomerReportsPictures?: boolean;
  customerReportsPictures?: any[]; // TODO: FP-8758 type me
  filters?: IFilter[];
  onChangeFilters?: (filters: IFilter[]) => void;
}

const styles = {
  imageListArea: {},
  pictureGallery: {},
};

const useStyles = makeStyles(styles);

function ActivityReportsGallery({
  customerReportsPictures,
  filters,
  isLoadingCustomerReportsPictures,
  onChangeFilters,
}: IPictureGalleryProps) {
  const [selectedCustomerReportPictureIndex, setSelectedCustomerReportIndex] =
    useState<number>(0);
  const [
    openCustomerReportPictureModalState,
    setOpenCustomerReportPictureModalState,
  ] = useState(false);
  const classes = useStyles();
  const lang = useTranslations();

  return (
    <Box
      style={{
        overflow: "hidden",
        paddingBottom: "50px",
      }}
      className={classes.pictureGallery}
    >
      <Grid
        container
        direction="column"
        spacing={3}
        style={{
          width: "100%",
        }}
      >
        <>
          <Typography
            style={{
              marginTop: "30px",
              marginLeft: "12px",
            }}
          >
            {
              lang.containers.lists.subCategories.items.detailDialog
                .activityReportsPicturesTitle
            }
          </Typography>
          <Grid item>
            <FilterContainer
              filters={filters || []}
              onChangeFilters={onChangeFilters}
            />
          </Grid>
          {isLoadingCustomerReportsPictures ? (
            <Grid item>
              <DialogPlaceHolder
                placeholderText={
                  lang.containers.lists.subCategories.items.detailDialog
                    .loadingActivityReportsPictures
                }
              />
            </Grid>
          ) : (
            <>
              {customerReportsPictures && customerReportsPictures.length > 0 ? (
                <>
                  <Grid
                    item
                    className={classes.imageListArea}
                    style={{
                      overflowY: "auto",
                      maxHeight: "500px",
                      padding: "0px",
                      justifyContent: "center",
                    }}
                  >
                    <PictureGalleryList
                      pictures={_.map(customerReportsPictures, (pic) => ({
                        url: pic.url,
                        label: pic.tag,
                        id: pic.id,
                      }))}
                      selectedLabel={undefined}
                      onImageClick={(_imageTag, imageIndex) => {
                        setSelectedCustomerReportIndex(imageIndex);
                        setOpenCustomerReportPictureModalState(true);
                      }}
                    />
                    <PictureGallerySlide
                      open={openCustomerReportPictureModalState}
                      onArrowClick={(newIndex) => {
                        setSelectedCustomerReportIndex(newIndex);
                      }}
                      pictures={_.map(customerReportsPictures, (pic) => ({
                        url: pic.url,
                        label: pic.tag,
                      }))}
                      currentIndex={selectedCustomerReportPictureIndex}
                      onModalClose={() => {
                        setOpenCustomerReportPictureModalState(false);
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <DialogPlaceHolder
                    placeholderText={
                      lang.containers.lists.subCategories.items.detailDialog
                        .emptyActivityReportsPictures
                    }
                  />
                </Grid>
              )}
            </>
          )}
        </>
      </Grid>
    </Box>
  );
}

export default ActivityReportsGallery;
