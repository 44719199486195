import { FunctionComponent, useContext, useEffect, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import _ from "lodash";
import { useSelector } from "react-redux";

import { GreyLight } from "assets/colors";
import BasicPlaceholder from "components/BasicPlaceholder/BasicPlaceholder";
import UserInfoCard from "components/Card/UserInfoCard";
import Sidepanel from "components/Sidepanel/Sidepanel";
import { LoggerContext } from "containers/app/AppProviders";
import { CustomerDialogConnected } from "containers/customers/subcategories/map/CustomerDialog";
import { allDashboardsSelector } from "containers/dashboards/redux/selectors";
import { DashboardFilterContext } from "containers/dashboards/subcategories/OptimetriksDashboardScreen";
import { allUsersComposedSelector } from "containers/users/redux/selectors";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import TLang from "model/application/Lang";
import ICustomer from "model/entities/Customer";
import {
  ICustomerDetailForGPSTracking,
  IDashboard,
  IKPI,
  ITimelineActionsForGPSTracking,
  KPI_TYPE,
  TTimelineData,
} from "model/entities/Dashboard";
import { IList } from "model/entities/List";
import { IMobileUser } from "model/entities/User";
import getFullName from "utils/formatting/getFullName";

import GeoTrackingChart from "./GeoTrackingChart";
import { IUserPositionData } from "./GeoTrackingChartContainer";
import TimelineSidePanel from "./Timeline/TimelineSidePanel";
import fetchMatchingRoutes from "./utils/fetchMatchingRoutes";

const styles = {
  GeoTrackingContainer: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
} as const;

const useStyles = makeStyles(styles);

interface IGeoTrackingChartCleanMatchingProps {
  selectedUser?: string;
  userFilter: { key: string }[];
  index: number;
  users: IMobileUser[];
  changeSelectedUser: (idUser?: string) => void;
  formattedData: IUserPositionData;
  simplifiedFormattedData: IUserPositionData; // used for posting to MapBox fetchMatchingRoutes which only allows < 100 points
  customerList: IList<ICustomer>;
  timelineActions: ITimelineActionsForGPSTracking[];
  customersVisited: ICustomerDetailForGPSTracking[];
  onRunQuery?: (
    // essential for the gps tracking dashboard
    dashboard: IDashboard,
    query: any,
    callback?: any,
    degradedModeKpis?: string[]
  ) => Promise<any>;
  queryFilters?: any;
}

const GeoTrackingChartCleanMatching: FunctionComponent<
  IGeoTrackingChartCleanMatchingProps
> = ({
  userFilter,
  index,
  customerList,
  users,
  selectedUser,
  changeSelectedUser,
  formattedData,
  simplifiedFormattedData,
  timelineActions,
  customersVisited,
  onRunQuery,
  queryFilters,
}) => {
  const classes = useStyles();
  const filtersContext = useContext(DashboardFilterContext);
  const logger = useContext(LoggerContext);

  const [matchingRoutes, setMatchingRoutes] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<
    string | undefined
  >();

  const lang = useTranslations();
  const allUsers = useSelector(allUsersComposedSelector);
  const dashboards = useSelector(allDashboardsSelector);

  const user = _.find(allUsers, { id: selectedUser });
  const [openPanel, setOpenPanel] = useState(!_.isUndefined(selectedUser));

  const dashboardsLang = lang.containers.dashboards.subCategories.dashboards;
  const customErrorsLang =
    dashboardsLang.createEditModal.timeline.customErrorMessages;

  const noDataMessageTitle = customErrorsLang.noData;
  const noDataMessageDescription = customErrorsLang.noDataDescription;
  const noUserSelectedTitle = customErrorsLang.noUserSelected;
  const noUserSelectedDescription = customErrorsLang.noUserSelectedDescription;

  function getTimeSelected() {
    if (filtersContext?.filters) {
      const dateFilter = _.find(filtersContext?.filters, { tag: "_date" });
      if (dateFilter && dateFilter.value.hasOwnProperty("startDate")) {
        return dateFilter.value["startDate"];
      }
    }
    return new Date();
  }

  useEffect(() => {
    if (!selectedUser) {
      return;
    }

    fetchMatchingRoutes(
      simplifiedFormattedData[selectedUser],
      logger,
      selectedUser
    ).then((routes) => setMatchingRoutes(routes));
  }, [simplifiedFormattedData, selectedUser, logger]);

  const getUserTimelineData = (lang: TLang): TTimelineData[] => {
    if (!selectedUser) {
      return [];
    }

    const fullUser = selectedUser
      ? _.find(users, { id: selectedUser })
      : undefined;

    return timelineActions
      .filter((action) => action.user_id === selectedUser)
      .map((action) => {
        const customer = _.find(customersVisited, {
          customer_id: action.customer_id,
        });

        return {
          ...action,
          user_name: getFullName(fullUser),
          user_phone: fullUser?.phone || "",
          date: new Date(action.timestamp),
          action: action.action_code,
          // we can't have the same string for all "unknown" customer_name's if the customer_id's are different,
          // because the actions would be grouped together and we don't want that (FP-4246)
          customer_name:
            customer?.name ||
            formatString(lang.genericTerms.unknownWithId, [
              action.customer_id || "",
            ]),
          customer_id: action.customer_id,
        };
      });
  };

  const timelineChart: IKPI<TTimelineData[]> = {
    description: "description",
    tag: "mytag",
    index: 0,
    title: "title",
    type: KPI_TYPE.TIMELINE,
    data: getUserTimelineData(lang),
  };

  const potentialUsers = Object.keys(formattedData).filter(
    (e) => Object.keys(formattedData[e]).length > 0
  );

  const formatTimelineTitle = () => {
    if (
      Object.keys(formattedData).length === 0 ||
      !Object.keys(formattedData).find(
        (e) => Object.keys(formattedData[e]).length > 0
      )
    ) {
      return (
        <BasicPlaceholder
          title={noDataMessageTitle}
          description={noDataMessageDescription}
        />
      );
    }
    return user && potentialUsers.includes(user.id) ? (
      <UserInfoCard user={user} />
    ) : (
      <BasicPlaceholder
        title={noUserSelectedTitle}
        description={noUserSelectedDescription}
        icon={
          <PersonOffOutlinedIcon fontSize="inherit" htmlColor={GreyLight} />
        }
      />
    );
  };

  const timelineData = {
    timelineChart: timelineChart,
    locationData: formattedData,
    selectedUser: selectedUser,
    timeSelected: getTimeSelected(),
    onOpenCustomerDialog: setSelectedCustomerId,
    // TODO: fix
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onRunQuery: onRunQuery!,
    queryFilters,
    potentialUsers,
  };

  function handleChangeSelectedUser(idUser?: string | undefined) {
    setOpenPanel(true);
    changeSelectedUser(idUser);

    const filters = {
      detailed_user: idUser,
      ...queryFilters,
    };

    const dashboard = _.find(dashboards, { id: "_gps_tracking" });
    if (dashboard && onRunQuery) {
      onRunQuery(dashboard, filters);
    }
  }

  function handleClosePanel() {
    setOpenPanel(false);
    changeSelectedUser(undefined);
  }

  const onCloseCustomerDialog = () => {
    setSelectedCustomerId(undefined);
  };

  const filteredUsers = _.filter(users, (user) => {
    const filteredUserIds = _.map(userFilter, (filter) => filter.key);
    return _.includes(filteredUserIds, user.id);
  });

  return (
    <div className={classes.GeoTrackingContainer}>
      <GeoTrackingChart
        key={index + "mapChart"}
        data={formattedData}
        matchingRoutes={matchingRoutes}
        users={filteredUsers}
        customerList={customerList}
        selectedUser={selectedUser}
        onChangeSelectedUser={handleChangeSelectedUser}
        onChangeSelectedCustomer={setSelectedCustomerId}
      />

      <Sidepanel
        open={openPanel}
        handleCloseSidePanel={handleClosePanel}
        title={
          lang.containers.lists.subCategories.map.createEditModal
            ?.inputTimelinePanel.title ?? ""
        }
      >
        <Box padding={"16px"}>
          {timelineData ? (
            <TimelineSidePanel
              timelineData={timelineData}
              timelineTitle={formatTimelineTitle()}
            />
          ) : null}
        </Box>
      </Sidepanel>

      {selectedCustomerId ? (
        <CustomerDialogConnected
          customerId={selectedCustomerId}
          onClose={onCloseCustomerDialog}
        />
      ) : null}
    </div>
  );
};

export default GeoTrackingChartCleanMatching;
