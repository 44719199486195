import { Box, Grid } from "@material-ui/core";
import _ from "lodash";

import { GreyDark } from "assets/colors";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import useTranslations from "hooks/useTranslations";
import { TInputAttributeLang } from "model/application/Lang";

import { getActionName } from "../modals/utils";

interface IDisplayProfileActions {
  childOptions: string[];
  fatherOption: string;
  fatherOptionIsChecked?: boolean;
  sectionTitle: string;
  defaultStatus: object;
  onChange: (value: any, name: string) => void;
}

const DisplayProfileActions = (props: IDisplayProfileActions) => {
  const lang = useTranslations();
  const {
    childOptions,
    fatherOption,
    defaultStatus,
    sectionTitle,
    onChange,
    fatherOptionIsChecked,
  } = props;

  const chunkBy3array = () => {
    const cut = _.chunk(childOptions, 3);
    return cut;
  };

  const childOptionsCut = chunkBy3array();

  return (
    <Box marginTop="-30px">
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: GreyDark,
          height: "24px",
          lineHeight: 1.2,
          margin: "43px 203px 0px 0",
        }}
      >
        {sectionTitle}
      </div>

      <Box marginLeft={"-13px"}>
        {/* Father option */}
        <InputMultipleSelect
          key={fatherOption}
          name={fatherOption}
          langlabel={
            { title: getActionName(fatherOption) } as TInputAttributeLang
          }
          lang={lang}
          defaultValue={
            fatherOptionIsChecked
              ? [
                  {
                    key: fatherOption,
                    label: fatherOption,
                  },
                ]
              : []
          }
          onChange={onChange}
          options={[
            {
              key: fatherOption,
              label: getActionName(fatherOption),
            },
          ]}
          viewMode="INLINE"
        />

        {/* Children options */}
        {fatherOptionIsChecked && (
          <Box paddingLeft={"30px"} display="flex">
            {_.map(childOptionsCut, (child, index) => (
              <Grid key={index} item xs={4}>
                {_.map(child, (action) => (
                  <InputMultipleSelect
                    key={action}
                    name={action}
                    langlabel={
                      { title: getActionName(action) } as TInputAttributeLang
                    }
                    lang={lang}
                    defaultValue={
                      _.isArray(defaultStatus[action])
                        ? defaultStatus[action]
                        : []
                    }
                    onChange={onChange}
                    options={[
                      {
                        key: action,
                        label: getActionName(action),
                      },
                    ]}
                    viewMode="INLINE"
                  />
                ))}
              </Grid>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DisplayProfileActions;
