import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";

import { IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";

const getItemGalleryPictures = (item: IListItem, schema: IListSchema[]) => {
  const pictureAttributes = _.filter(schema, {
    type: CUSTOM_FIELD_TYPE.PICTURE,
  });

  const pictureList = _.compact(
    _.map(pictureAttributes, (attribute) => getGalleryPicture(item, attribute))
  );
  return pictureList;
};

const getGalleryPicture = (item: IListItem, attribute: IListSchema) => {
  if (!item[attribute.column_tag]) {
    return;
  }
  return {
    label: attribute.column_name,
    url: item[attribute.column_tag].url,
  };
};

export default getItemGalleryPictures;
