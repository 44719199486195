import { ComponentProps, ReactNode } from "react";

import { InputLabel as MUIInputLabel, makeStyles } from "@material-ui/core";
import _ from "lodash";

import { TViewMode } from "model/application/modal/CreateEditModal";

import CustomInputTooltip from "./CustomInputTooltip";

const useStyles = makeStyles({
  InputLabel: ({ lineHeight }: Partial<IInputLabelProps>) => ({
    fontFamily: "BasierCircle",
    fontWeight: 500,
    lineHeight: lineHeight ?? "20px",
    fontSize: "16px",
    position: "relative",
  }),
  LabelIcon: {
    "& .material-icons-outlined": {
      fontWeight: 500,
      fontSize: "16px",
    },
  },
} as const);
interface IInputLabelProps {
  title?: string;
  required?: boolean;
  // If we want the label to turn red,
  // this means the error logic must be handled by the label's parent
  // which is not always handy (see InputBaseLayout, the input and the label are siblings)
  error?: string; // TODO: legacy ?
  tooltip?: string;
  viewMode?: TViewMode;
  htmlFor?: string;
  lineHeight?: string;
  icons?: ReactNode[];
  style?: ComponentProps<typeof MUIInputLabel>["style"];
}

const InputLabel = ({
  title,
  required,
  // error,
  tooltip,
  viewMode = "CREATE",
  htmlFor,
  icons,
  lineHeight,
  style,
}: IInputLabelProps) => {
  const classes = useStyles({ lineHeight });
  return (
    <MUIInputLabel
      className={classes.InputLabel}
      required={required}
      style={{ marginBottom: 0, ...(style || {}) }}
      htmlFor={htmlFor}
      // error={!!error}
    >
      <span>
        <span>
          {title}
          {viewMode === "CREATE" || !title ? "" : ":"}
        </span>

        {!_.isEmpty(icons) && (
          <span className={classes.LabelIcon}>{icons}</span>
        )}
      </span>

      {tooltip && (
        <span
          style={{
            marginLeft: required ? "12px" : "5px",
            position: "absolute",
          }}
        >
          <CustomInputTooltip text={tooltip} placement="right" />
        </span>
      )}
    </MUIInputLabel>
  );
};

export default InputLabel;
