import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";

import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";
import TLang from "model/application/Lang";

export type TEventsTableColumnData = Omit<TCalendarEvent, "status"> & {
  customer_name?: string | JSX.Element;
  field_user_name?: string;
  phone_number?: string;
  status: JSX.Element | CalendarEventStatus;
  statusText: CalendarEventStatus;
};

interface ICreateEventsTableColumns {
  lang: TLang;
}
export function CreateEventsTableColumns({
  lang,
}: ICreateEventsTableColumns): TColumnType<keyof TEventsTableColumnData>[] {
  return [
    {
      name: "customer_name",
      label:
        lang.containers.pictures.subCategories.pictures.createEditModal
          .customerId.title,
      type: TABLE_COLUMN_TYPE.CUSTOM,
    },
    {
      name: "start_time",
      label:
        lang.containers.users.subCategories.mobileUsers.createEditModal
          .inputStartDate.title,
      type: TABLE_COLUMN_TYPE.DATE_TIME,
    },
    {
      name: "field_user_name",
      label: "Field user name",
    },
    {
      name: "phone_number",
      label:
        lang.containers.users.subCategories.mobileUsers.createEditModal
          .inputPhone.title,
    },
    {
      name: "status",
      label: lang.components.filters.status,
      type: TABLE_COLUMN_TYPE.CUSTOM,
    },
    {
      name: "description",
      label: lang.containers.clients.subCategories.clients.columns.description,
    },
  ];
}
