import moment from "moment";

import IPictureSortBy from "containers/pictures/model/IPictureSortBy";
import { IClient } from "model/entities/Client";
import { IList } from "model/entities/List";
import IPicture, {
  IPictureFromItems,
  IPictureFromSubmissions,
} from "model/entities/Picture";
import IUser from "model/entities/User";
import { IActivity } from "model/entities/Workflow";
import { DATE_FORMAT } from "utils/constants";

import {
  findUserNameFromUsersList,
  IPictureWithIndex,
  PictureActions,
  TypeSortList,
} from "../utils";

const getColumnNameFromItemList = (
  picture: IPictureFromItems,
  allLists: IList[]
): string => {
  const { tag, list_id } = picture;

  const list = allLists.find((l) => l.id === list_id);

  if (!list || !list.schema) {
    return "";
  }

  const itemTag = list.schema.find((l) => l.column_tag === tag);

  return itemTag ? itemTag.column_name : "";
};

export const getTagNameFromActivity = (
  picture: IPictureFromSubmissions,
  allActivities: IActivity[]
): string => {
  const { tag, workflow_id } = picture;

  const workflow = allActivities.find((l) => l.id === workflow_id);

  if (
    !workflow ||
    !workflow.questionnaire ||
    !workflow.questionnaire.questions
  ) {
    return "";
  }

  const { questions } = workflow.questionnaire;

  let result = "";
  for (let i = 0; i < questions.length; i++) {
    const questionTag = questions.find((q) => q.tag === tag);
    if (questionTag && questionTag.question_text) {
      result = questionTag.question_text;
      break;
    }
  }

  return result;
};

interface IProps {
  allUsers: IUser[];
  allLists: IList[];
  allActivities: IActivity[];
  client?: IClient;
}

export interface ISortingPicture {
  [key: string]: IPictureWithIndex[];
}

const getFormattedValue = (
  picture: IPicture,
  sortBy: IPictureSortBy,
  props: IProps
): string => {
  const { allUsers, allLists, allActivities, client } = props;
  switch (sortBy.name) {
    case TypeSortList.Date: {
      moment.locale(client?.language ?? "en");
      return moment(picture.timestamp).format(DATE_FORMAT);
    }
    case TypeSortList.User:
      return findUserNameFromUsersList(picture.user_id, allUsers);

    case TypeSortList.Tag:
      if (picture.action === PictureActions.UploadFileForItem) {
        return getColumnNameFromItemList(
          picture as IPictureFromItems,
          allLists
        );
      } else {
        return getTagNameFromActivity(
          picture as IPictureFromSubmissions,
          allActivities
        );
      }

    default:
      return "";
  }
};

const getSortedPicturesList = (
  pictures: IPictureWithIndex[],
  sortBy: IPictureSortBy,
  props: IProps
): ISortingPicture => {
  return pictures.reduce<ISortingPicture>((result, picture) => {
    const formattedValue = getFormattedValue(picture, sortBy, props);
    const newResult = { ...result };

    if (!formattedValue) {
      if (!newResult.others) {
        newResult.others = [];
      }
      newResult.others.push(picture);
    } else {
      if (!newResult[formattedValue]) {
        newResult[formattedValue] = [];
      }
      newResult[formattedValue].push(picture);
    }

    return newResult;
  }, {});
};

export default getSortedPicturesList;
