import { TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";

export function computeEventsIndexes(events: TCalendarEvent[]) {
  const computedEventsWithIndex = _(events)
    .filter((e) => {
      return _.has(e, "index");
    })
    .orderBy("index", "asc")
    .map((e, index) => {
      return {
        ...e,
        index,
        full_day: true,
      };
    })
    .value();

  const computedEventsWithoutIndex = _(events)
    .filter((e) => {
      return !_.has(e, "index");
    })
    .map((e, index) => {
      return {
        ...e,
        index: computedEventsWithIndex.length + index,
        full_day: true,
      };
    })
    .value();

  return _.orderBy(
    [...computedEventsWithIndex, ...computedEventsWithoutIndex],
    "index",
    "asc"
  );
}
