import { useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import InputBoolean from "components/Input/InputBoolean";
import { getErrorFromAttribute } from "components/Input/InputMultipleCreate/InputMultipleCreate";
import InputText from "components/Input/InputText/InputText";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useFormState from "hooks/useFormState";
import { IOption } from "model/application/components";
import TLang from "model/application/Lang";
import { isWorkspaceTemplate } from "utils/clients/utils";
import { convertNameToTag } from "utils/convertNameToTag";

interface IInputOptionProps {
  onChange: (e: IOption) => void;
  additionnalProps: {
    options: any[];
    deprecatedOptions: string[];
    allowWithUnderscore?: boolean;
  };
  element: IOption;
  lang: TLang;
  isCreation: boolean;
}

export const OptionForm = ({
  element,
  lang,
  onChange,
  isCreation,
}: IInputOptionProps) => {
  const formStateHook = useFormState({
    initAttributes: element,
  });
  const autoSetLabel = useRef(true);
  const labelToDisplay = lang.modal.createEditOption;

  const client = useSelector(getSelectedClient);

  const handleChange = (value: any, name: string) => {
    if (name === "label") {
      formStateHook.handleInputChange(value, name);
      if (autoSetLabel.current && isCreation) {
        formStateHook.handleInputChange(convertNameToTag(value), "key");
      }
    }
    if (name === "key") {
      autoSetLabel.current = false;
      formStateHook.handleInputChange(convertNameToTag(value), name);
    }

    if (name === "ignore_propagation")
      formStateHook.handleInputChange(value, name);
  };

  useEffect(() => {
    onChange(formStateHook.attributes);
  }, [formStateHook.attributes, onChange]);

  return (
    <div>
      <InputText
        name="label"
        lang={labelToDisplay.inputLabel}
        defaultValue={formStateHook.attributes.label}
        onChange={handleChange}
        error={getErrorFromAttribute(formStateHook.attributes, "label")}
        required={true}
        debounceDuration={0}
      />

      {JSON.stringify(formStateHook.errors, null, 4)}

      <InputText
        name="key"
        lang={labelToDisplay.inputKey}
        defaultValue={formStateHook.attributes.key}
        onChange={handleChange}
        error={getErrorFromAttribute(formStateHook.attributes, "key")}
        required={true}
        replaceSpaces
        lowercase
        debounceDuration={0}
      />

      {isWorkspaceTemplate(client) && (
        <InputBoolean
          name="ignore_propagation"
          defaultValue={formStateHook.attributes["ignore_propagation"]}
          onChange={handleChange}
          lang={labelToDisplay.inputIgnorePropagation}
        />
      )}
    </div>
  );
};

export default OptionForm;
