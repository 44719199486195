import { ComponentProps, useState } from "react";

import {
  Box,
  Button,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";

import TLang from "model/application/Lang";

import styles from "../styles";
import { TickBox } from "./InputMultipleSelect";

const useStyles = makeStyles(styles as any);
interface IInputMultipleSelectMenuPropsBase {
  lang: TLang;
  options: any[];
  onSave: () => void;
  onCancel: () => void;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  multipleSelection?: boolean;
  langlabel: any;
  isOpen: boolean;
  keyArrSelected: string[];
  onChangeOption: (option: any) => void;
  disabled?: boolean;
}
export interface IInputMultipleSelectMenuProps
  extends IInputMultipleSelectMenuPropsBase,
    Omit<
      ComponentProps<typeof Paper>,
      keyof IInputMultipleSelectMenuPropsBase
    > {}

const InputMultipleSelectMenu = ({
  lang,
  options,
  onSave,
  onCancel,
  onSelectAll,
  onDeselectAll,
  multipleSelection,
  langlabel,
  isOpen,
  keyArrSelected,
  onChangeOption,
  disabled = false,
  ...rest
}: IInputMultipleSelectMenuProps) => {
  const classes = useStyles();

  const [optionsSearch, setOptionsSearch] = useState(options);
  const [searchValue, setSearchValue] = useState("");

  const checkSeletedOption = (option: any) => {
    return keyArrSelected.includes(option.key);
  };

  const handleSearch = (searchTerm: string) => {
    if (!_.isEmpty(searchTerm)) {
      setOptionsSearch(
        options.filter((option: any) =>
          option.label.toUpperCase().includes(searchTerm.toUpperCase())
        )
      );
      setSearchValue(searchTerm);
    } else {
      setOptionsSearch(options);
      setSearchValue("");
    }
  };

  return (
    <Paper
      elevation={2}
      className={classes.paperPart}
      style={{ display: isOpen ? "block" : "none", ...(rest.style || {}) }}
      {..._.omit(rest, ["style"])}
    >
      <Typography style={{ margin: 16, fontWeight: 500 }}>
        {options.length > 9 ? (
          <TextField
            className={classes.searchPart}
            disabled={disabled}
            autoFocus
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={
              langlabel.placeholder ? langlabel.placeholder : "Search for name"
            }
            variant="outlined"
            InputProps={{
              endAdornment: <SearchIcon color="disabled" />,
            }}
            value={searchValue}
          />
        ) : (
          <span>Select an option</span>
        )}
      </Typography>
      <Box className={classes.boxContainer}>
        <Box className={classes.childrenBox}>
          {_.orderBy(optionsSearch, (opt) => opt.highlight, "desc").map(
            (opt: any) => {
              return (
                <MenuItem
                  key={opt.key}
                  title={opt.label}
                  onClick={() => {
                    !opt.disabled && !disabled && onChangeOption(opt);
                  }}
                >
                  <TickBox
                    disabled={opt.disabled}
                    tooltip={opt.tooltip}
                    option={opt}
                    multipleSelection={multipleSelection}
                    checked={checkSeletedOption(opt)}
                  />
                </MenuItem>
              );
            }
          )}
        </Box>
      </Box>
      <div className={classes.buttonsContainer}>
        <div className={classes.selectDeselectAllButton}>
          {onDeselectAll && (
            <Button
              variant="text"
              onClick={onDeselectAll}
              disabled={disabled}
              className={classes.deselectAllButton}
            >
              {lang.modal.deselectAll}
            </Button>
          )}

          {onSelectAll && (
            <Button
              variant="text"
              onClick={onSelectAll}
              disabled={disabled}
              className={classes.selectAllButton}
            >
              {lang.modal.selectAll}
            </Button>
          )}
        </div>
        <div className={classes.cancelSaveContainer}>
          <Button
            onClick={onCancel}
            color="default"
            variant="outlined"
            className={classes.cancelButton}
            disableElevation
          >
            {(lang as TLang).genericTerms.cancel}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={onSave}
            className={classes.saveButton}
            disableElevation
            disabled={disabled}
          >
            {(lang as TLang).genericTerms.save}
          </Button>
        </div>
      </div>
    </Paper>
  );
};
export default InputMultipleSelectMenu;
