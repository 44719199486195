import React, { useRef, useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import { TagData } from "@yaireo/tagify";
import MixedTags, {
  TagifyTagsReactProps,
} from "@yaireo/tagify/dist/react.tagify";
import classNames from "classnames";
import {
  DETAILED_ACTION_TYPE,
  DETAILED_OBJECT_CATEGORY,
  IMetaExpression,
  parseRawMetaExpression,
} from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import { createMetaExpressionAction } from "containers/workflows/redux/meta_expressions/actions";
import { allMetaExpressionsSelector } from "containers/workflows/redux/meta_expressions/selectors";
import useActions from "hooks/useActions";
import { getDetailedActionName } from "model/application/ActionCode";

import { addRequiredCheck } from "../utils";
import CreateEditModalMetaExpression from "./CreateEditModalMetaExpression";
import { IInputMetaExpressionProps } from "./InputMetaExpression";
import styles from "./InputMetaExpression.styles";
import { getFormattedDefaultValue } from "./utils/getFormattedDefaultValue";
import { getTagifySettings } from "./utils/getTagifySettings";

const useStyles = makeStyles(styles as any);

interface IInputMetaExpressionFieldProps extends IInputMetaExpressionProps {
  modalType: "create" | "edit";
  isMetaBuilderOpen: boolean;
  onClickTag: () => void;
  onCloseModal: () => void;
  onSave?: () => void;
}

const InputMetaExpressionField: React.FC<IInputMetaExpressionFieldProps> = ({
  lang,
  name,
  onChange,
  bindedTransfo,
  calledFrom,
  currentActivity,
  currentList,
  currentWorkflow,
  currentTrigger,
  defaultValue = "",
  disabled = false,
  error = "",
  expressionType,
  matrixTag,
  maxTags,
  numberIndex,
  placeholder = lang.components.inputMetaExpression.options.placeholder,
  required = false,
  type,
  modalType,
  isMetaBuilderOpen,
  onClickTag,
  onCloseModal,
  // eslint-disable-next-line unused-imports/no-unused-vars
  onSave,
}) => {
  const allMetaExpressions = useSelector(allMetaExpressionsSelector);
  const metaExpressions = allMetaExpressions;
  const classes = useStyles();
  const tagifyRef = useRef<any | undefined>(undefined);

  const [metaFormattedValue, setMetaFormattedValue] =
    useState<string>(defaultValue);

  const [selectedMetaExpression, setSelectedMetaExpression] = useState<
    Partial<IMetaExpression>
  >({});

  const [selectedTag, setSelectedTag] = useState<TagData | undefined>(
    undefined
  );

  const createMetaExpression = useActions(createMetaExpressionAction);

  const handleConfirmCreateMetaExpression = async (
    metaExpression: IMetaExpression
  ) => {
    const actionName = getDetailedActionName(
      DETAILED_ACTION_TYPE.CREATE,
      DETAILED_OBJECT_CATEGORY.METAEXPRESSION
    );
    const expression = await createMetaExpression(actionName, metaExpression);
    if (expression && tagifyRef && tagifyRef.current) {
      try {
        // lib can break if selection is not initialized (e.g by setting focus on input)
        // see https://github.com/yairEO/tagify/issues/524#issuecomment-1132019225
        if (!tagifyRef.current.state.selection) {
          tagifyRef.current.DOM.input.focus();
          tagifyRef.current.setStateSelection();
        }
        const tagCount = _.size(tagifyRef.current.getTagElms());
        if (maxTags && tagCount >= maxTags) {
          return;
        }

        // insert empty text node to fix trouble of missing ending space when empty
        tagifyRef.current.injectAtCaret("");

        const tagElm = tagifyRef.current.createTagElem({
          value: metaExpression.title,
          expression: expression,
        });

        tagifyRef.current.injectAtCaret(tagElm);
        const elm = tagifyRef.current.insertAfterTag(tagElm);
        tagifyRef.current.placeCaretAfterNode(elm);
        tagifyRef.current.replaceTextWithNode();
        tagifyRef.current.insertAfterTag(tagElm);
      } catch (e) {
        console.error(e);
      }
    }
  };
  const handleConfirmEditMetaExpression = async (
    metaExpression: IMetaExpression
  ) => {
    const actionName = getDetailedActionName(
      DETAILED_ACTION_TYPE.EDIT,
      DETAILED_OBJECT_CATEGORY.METAEXPRESSION
    );
    const expression = await createMetaExpression(
      actionName,
      metaExpression as IMetaExpression
    );
    if (expression && selectedTag) {
      const oldElm = tagifyRef.current.getTagElmByValue(selectedTag.value);
      const newData = {
        value: metaExpression.title,
        expression: expression,
      };
      if (oldElm) {
        tagifyRef.current.replaceTag(oldElm, newData);
      } else {
        tagifyRef.current.createTagElem(newData);
      }
    }
  };

  const handleClickTag = (
    event: Parameters<NonNullable<TagifyTagsReactProps["onClick"]>>[0]
  ) => {
    const expression = event.detail.data;
    let formattedExpression: Partial<IMetaExpression> = {};

    formattedExpression = {
      ...parseRawMetaExpression(expression["expression"] ?? ""),
      title: expression["value"],
    };

    if (onClickTag) {
      onClickTag();
    }
    setSelectedMetaExpression(formattedExpression);
    setSelectedTag(expression);
  };

  const handleChange = (event: any) => {
    const value: string = (event.detail.value as string).trim();

    const metas = value.match(/\[\[(.*?)\]\]/gs)?.map((val) => {
      return val.replace(/\[\[/g, "").replace(/\]\]/g, "");
    });

    if (onChange) {
      let newValue = value;
      value.match(/\[\[(.*?)\]\]/gs)?.forEach((v, index) => {
        try {
          const expression = metas && JSON.parse(metas[index]).expression;
          newValue = newValue.replace(
            v,
            expression.includes("__active_")
              ? `$\{${expression}}`
              : `$^${expression}^`
          );
        } catch (e: any) {
          // do nothing: we can arrive here in some specific cases like trying to insert a double python array in a script
        }
      });
      newValue = newValue.replace(/\n$/, "");

      setMetaFormattedValue(newValue);
      onChange(newValue, name);
    }
  };

  const finalError = addRequiredCheck(metaFormattedValue, error, required);

  return (
    <>
      <Box
        className={classNames(
          classes.TagifyInputWrapper,
          _.isEmpty(finalError) ? "default" : "error"
        )}
      >
        <MixedTags
          tagifyRef={tagifyRef}
          defaultValue={getFormattedDefaultValue({
            defaultValue,
            metaExpressions,
          })}
          name={name}
          InputMode="textarea"
          settings={getTagifySettings({
            metaExpressions,
            disabled,
            maxTags,
            type,
          })}
          onChange={handleChange}
          placeholder={placeholder}
          showDropdown
          onClick={handleClickTag}
        />
        <CreateEditModalMetaExpression
          handleConfirmCreateMetaExpression={handleConfirmCreateMetaExpression}
          handleConfirmEditMetaExpression={handleConfirmEditMetaExpression}
          isMetaBuilderOpen={isMetaBuilderOpen}
          modalType={modalType}
          onClose={onCloseModal}
          selectedMetaExpression={selectedMetaExpression as IMetaExpression}
          bindedTransfo={bindedTransfo as any}
          calledFrom={calledFrom}
          currentActivity={currentActivity}
          currentList={currentList}
          currentWorkflow={currentWorkflow}
          currentTrigger={currentTrigger}
          expressionType={expressionType}
          numberIndex={numberIndex}
          matrixTag={matrixTag}
          type={type}
        />
      </Box>
    </>
  );
};

export default InputMetaExpressionField;
export const META_EXPRESSION_REGEX = /\$\^([^]*?)\^|\$\{(.*?)\}/gs;
