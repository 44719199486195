import { useState } from "react";

import _ from "lodash";

interface IUseArrayBrowser {
  itemIds: string[];
  initialId?: string;
  // allowLoop // Allow looping from last item to first item and vice-versa
}

/**
 * Helper to browser through a list of items represented by their ids
 * Note: onPrev and onNext are undefined on the edges, this is useful for components
 * that will receive them as onClick props so they can be ignored
 */
const useArrayBrowser = ({ itemIds = [], initialId }: IUseArrayBrowser) => {
  const initialIndex = _.indexOf(itemIds, initialId);
  const [index, setIndex] = useState(initialIndex);

  const incrementIndex = () => setIndex((index) => index - 1);
  const decrementIndex = () => setIndex((index) => index + 1);

  const onPrev = index > 0 ? incrementIndex : undefined;
  const onNext = index < itemIds.length - 1 ? decrementIndex : undefined;

  const setCurrentId = (id?: string | null) => {
    if (!id) {
      return setIndex(-1);
    }
    setIndex(_.indexOf(itemIds, id));
  };

  const currentId = itemIds[index];
  return { currentId, setCurrentId, onPrev, onNext };
};

export default useArrayBrowser;
