import { IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { IAction, IActionError, IBeginAction } from "redux/store/model";

import { TItemMobileUserLink } from "../subcategories/items/utils/bulkModalUtils";
import { IChangeTagParams } from "./actions";
import * as types from "./actionTypes";

/**
 * Action creator return the type of action that informs reducer a list is being created. This will normally
 * enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createListBeginAction(): IBeginAction {
  return { type: types.CREATE_LIST_BEGIN };
}

/**
 * List failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createListFailureAction(error: any): IActionError {
  return { type: types.CREATE_LIST_FAILURE, error };
}

export interface ICreateListSuccessAction extends IAction {
  list: IList;
}

/**
 * List create success action is used to inform reducer of successful ajax request
 * @param {Object} list Object with List information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createListSuccessAction(list: IList): ICreateListSuccessAction {
  return { type: types.CREATE_LIST_SUCCESS, list };
}

export interface ICreateItemsSuccessAction extends IAction {
  listId: string;
  items: IListItem[];
  listSchema: IListSchema[];
}

/**
 * Item creation success action is used to inform reducer of successful ajax request for creating a new item for a
 * given list in a client. will return an action with the type and payload
 * @param {String} listId List id of the newly inserted items
 * @param {Array} items List of items to insert in the list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createItemsSuccessAction(
  listId: string,
  items: IListItem[],
  listSchema: IListSchema[]
): ICreateItemsSuccessAction {
  return {
    type: types.CREATE_ITEMS_SUCCESS,
    listId,
    items,
    listSchema,
  };
}

/**
 * Action creator return the type of action that informs reducer an item is being created for a list
 * this will normally enable updating a boolean value
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createItemsBeginAction(): IBeginAction {
  return { type: types.CREATE_ITEMS_BEGIN };
}

/**
 * Object failure action is used to inform reducer of failed ajax request
 * @param {Object} error Object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function createItemsFailureAction(error: any): IActionError {
  return { type: types.CREATE_ITEMS_FAILURE, error };
}

/**
 * Fetch All lists begin action. is used to dispatch action to the redux store a fetch all list request is being
 * requested.
 * @return {Object} Object with type of action
 * */
export function fetchListsForClientBeginAction(): IBeginAction {
  return { type: types.FETCH_LISTS_FOR_CLIENT_BEGIN };
}

/**
 * Fetch All List Failure action dispatched when there was an error fetching all lists. This will take in the
 * error object that will be used for logging.
 * @param {Object} error Error object with information of the failure
 * @return {Object} Object with type of action and the error object
 * */
export function fetchListsForClientFailureAction(error: any): IActionError {
  return { type: types.FETCH_LISTS_FOR_CLIENT_FAILURE, error };
}

export interface IFetchListsForClientSuccessAction extends IAction {
  lists: IList[];
}

/**
 * Fetch all lists success action, which is dispatched when there was a successful retrieval of lists from the API
 * or redux store. This dispatches the client id and the array of list objects
 * @param {Array} lists An array of list objects
 * @return {Object} Object Object with type of action and the array of list objects
 * */
export function fetchListsForClientSuccessAction(
  lists: IList[]
): IFetchListsForClientSuccessAction {
  return {
    type: types.FETCH_LISTS_FOR_CLIENT_SUCCESS,
    lists,
  };
}
/**
 * Fetch All customer's reports pictures begin action.
 * requested.
 * @return {Object} Object with type of action
 * */
export function fetchCustomerReportsPicturesBeginAction(): IBeginAction {
  return { type: types.FETCH_CUSTOMER_REPORTS_PICTURES_BEGIN };
}

/**
 * Fetch customer's reports pictures Failure action dispatched when there was an error fetching all pictures. This will take in the
 * error object that will be used for logging.
 * @param {Object} error Error object with information of the failure
 * @return {Object} Object with type of action and the error object
 * */
export function fetchCustomerReportsPicturesFailureAction(
  error: any
): IActionError {
  return { type: types.FETCH_CUSTOMER_REPORTS_PICTURES_FAILURE, error };
}

export interface IFetchCustomerReportsPicturesSuccessAction extends IAction {
  customer_reports_pictures: any[]; // TODO: FP-8758 type me
}

/**
 * Fetch all customer's reports pictures success action, which is dispatched when there was a successful retrieval of pictures from the API
 * or redux store. This dispatches the client id and the array of pictures objects
 * @param {Array} pictures An array of picture objects
 * @return {Object} Object Object with type of action and the array of picture objects
 * */
export function fetchCustomerReportsPicturesSuccessAction(
  customer_reports_pictures: any[] // TODO: FP-8758 type me
): IFetchCustomerReportsPicturesSuccessAction {
  return {
    type: types.FETCH_CUSTOMER_REPORTS_PICTURES_SUCCESS,
    customer_reports_pictures,
  };
}

/**
 * Action creator for setting the flag when fetching items for a given list
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function fetchItemsForListBeginAction(detail?: boolean): IBeginAction {
  return {
    type: detail ? types.FETCH_ITEM_BEGIN : types.FETCH_ITEMS_BEGIN,
  };
}

export interface IFetchListByIdSuccessAction extends IAction {
  list: IList;
}

export interface IFetchItemsForListSuccessAction extends IAction {
  listId: string;
  items: IListItem[];
  item_count: number;
  offset: number;
  eraseExisting: boolean;
  listSchema: IListSchema[];
  query?: any;
  storeItemsCount?: boolean;

  storeAsTableItems?: boolean;
  clearExistingTableItems?: boolean;
}

/**
 * Action creator dispatched when there is a successful fetch of items for a given list
 * @param {String} listId List Id we are fetching items for
 * @param {Array} items Array of list items
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function fetchItemsForListSuccessAction(
  listId: string,
  items: IListItem[],
  item_count: number,
  offset: number,
  eraseExisting: boolean,
  listSchema: IListSchema[],
  query?: any,
  storeItemsCount?: boolean,

  storeAsTableItems?: boolean,
  clearExistingTableItems?: boolean
): IFetchItemsForListSuccessAction {
  return {
    type: types.FETCH_ITEMS_SUCCESS,
    listId,
    items,
    item_count,
    offset,
    eraseExisting,
    listSchema,
    query,
    storeItemsCount,

    storeAsTableItems,
    clearExistingTableItems,
  };
}

/**
 * Action creator for setting the flag when fetching items for a given list
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function fetchItemsForListFailureAction(error: any): IActionError {
  return {
    type: types.FETCH_ITEMS_FAILURE,
    error,
  };
}

/**
 * Update List action used to inform reducer of failed ajax request to update a list
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateListFailureAction(error: any): IActionError {
  return { type: types.UPDATE_LIST_FAILURE, error };
}

export interface IUpdateListSuccessAction extends IAction {
  list: IList;
}

/**
 * Update List action used to inform reducer of failed ajax request to update a list
 * @param {Object} list Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateListSuccessAction(list: IList): IUpdateListSuccessAction {
  return { type: types.UPDATE_LIST_SUCCESS, list };
}

/**
 * Update list action used to inform reducer of failed ajax request to update a list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateListBeginAction(): IBeginAction {
  return { type: types.UPDATE_LIST_BEGIN };
}

/**
 * Delete List action used to inform reducer of beginning ajax request to delete a list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteListBeginAction(): IBeginAction {
  return { type: types.DELETE_LIST_BEGIN };
}

export interface IDeleteListSuccessAction extends IAction {
  id: string;
}

/**
 * Delete List action used to inform reducer of successful ajax request to delete a list
 * @param {String} id Id of the list to delete
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteListSuccessAction(id: string): IDeleteListSuccessAction {
  return { type: types.DELETE_LIST_SUCCESS, id };
}

/**
 * Delete List action used to inform reducer of successful ajax request to delete a list
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function deleteListFailureAction(error: any): IActionError {
  return { type: types.DELETE_LIST_FAILURE, error };
}

/**
 * Archive List action used to inform reducer of beginning ajax request to archive a list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveListBeginAction(): IBeginAction {
  return { type: types.ARCHIVE_LIST_BEGIN };
}

export interface IArchiveListSuccessAction extends IAction {
  id: string;
}

/**
 * Archive List action used to inform reducer of successful ajax request to archive a list
 * @param {String} id Id of the list to archive
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveListSuccessAction(
  id: string
): IArchiveListSuccessAction {
  return { type: types.ARCHIVE_LIST_SUCCESS, id };
}

/**
 * Archive List action used to inform reducer of successful ajax request to archive a list
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function archiveListFailureAction(error: any): IActionError {
  return { type: types.ARCHIVE_LIST_FAILURE, error };
}

/**
 * Restore List action used to inform reducer of beginning ajax request to restore a list
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreListBeginAction(): IBeginAction {
  return { type: types.RESTORE_LIST_BEGIN };
}

export interface IRestoreListSuccessAction extends IAction {
  id: string;
}

/**
 * Restore List action used to inform reducer of successful ajax request to restore a list
 * @param {String} id Id of the list to restore
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreListSuccessAction(
  id: string
): IRestoreListSuccessAction {
  return { type: types.RESTORE_LIST_SUCCESS, id };
}

/**
 * Restore List action used to inform reducer of successful ajax request to restore a list
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function restoreListFailureAction(error: any): IActionError {
  return { type: types.RESTORE_LIST_FAILURE, error };
}

/**Action creator that returns an action type stating that item deletion has just commenced*/
export function deleteItemsBeginAction(): IBeginAction {
  return {
    type: types.DELETE_ITEMS_BEGIN,
  };
}

export interface IDeleteItemsSuccessAction extends IAction {
  listId: string;
  ids: string[];
}

/**
 * Delete items from list success action creator is dispatched when there is a successful deletion of a
 * item from a list
 * @param {String} listId List id the items belongs to
 * @param {String} ids Ids of the deleted items
 * @returns {Object} Object with action type and payload
 */
export function deleteItemsSuccessAction(
  listId: string,
  ids: string[]
): IDeleteItemsSuccessAction {
  return {
    type: types.DELETE_ITEMS_SUCCESS,
    listId,
    ids,
  };
}

/**
 * Action creator returning the schema of the list with the new filters
 * was a failure in deleting items from a list.
 * @param {Object} error Error Object
 * @returns {Object} Object with type of action and error objet
 */
export function deleteItemsFailureAction(error: any): IActionError {
  return {
    type: types.DELETE_ITEMS_FAILURE,
    error,
  };
}

/**Action creator that returns an action type stating a object from a list archive has just commenced*/
export function archiveItemsBeginAction(): IBeginAction {
  return {
    type: types.ARCHIVE_ITEMS_BEGIN,
  };
}

export interface IArchiveItemsSuccessAction extends IAction {
  listId: string;
  ids: string[];
}

/**
 * Archive items from list success action creator is dispatched when there is a successful archive an
 * items from a list
 * @param {String} listId List id the items belongs to
 * @param {String} ids Ids of the archived items
 * @returns {Object} Object with action type and payload
 */
export function archiveItemsSuccessAction(
  listId: string,
  ids: string[]
): IArchiveItemsSuccessAction {
  return {
    type: types.ARCHIVE_ITEMS_SUCCESS,
    listId,
    ids,
  };
}

/**
 * Action creator returning the schema of the list with the new filters
 * was a failure in archiving items from a list.
 * @param {Object} error Error Object
 * @returns {Object} Object with type of action and error objet
 */
export function archiveItemsFailureAction(error: any): IActionError {
  return {
    type: types.ARCHIVE_ITEMS_FAILURE,
    error,
  };
}

/**Action creator that returns an action type stating a restoration of items has just commenced*/
export function restoreItemsBeginAction(): IBeginAction {
  return {
    type: types.RESTORE_ITEMS_BEGIN,
  };
}

export interface IRestoreItemsSuccessAction extends IAction {
  listId: string;
  ids: string[];
}

/**
 * Restore items from list success action creator is dispatched when there is a successful restore an
 * item from a list
 * @param {String} listId List id the items belongs to
 * @param {String} ids Ids of the restored items
 * @returns {Object} Object with action type and payload
 */
export function restoreItemsSuccessAction(
  listId: string,
  ids: string[]
): IRestoreItemsSuccessAction {
  return {
    type: types.RESTORE_ITEMS_SUCCESS,
    listId,
    ids,
  };
}

/**
 * Action creator returning the schema of the list with the new filters
 * was a failure in restoring items from a list.
 * @param {Object} error Error Object
 * @returns {Object} Object with type of action and error objet
 */
export function restoreItemsFailureAction(error: any): IActionError {
  return {
    type: types.RESTORE_ITEMS_FAILURE,
    error,
  };
}

/**
 * Update Item action used to inform reducer of failed ajax request to update an item
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateItemsFailureAction(error: any): IActionError {
  return { type: types.UPDATE_ITEMS_FAILURE, error };
}

export interface IUpdateItemsSuccessAction extends IAction {
  items: IListItem[];
  listId: string;
}

/**
 * Update Item action used to inform reducer of failed ajax request to update an item
 * @param {Array} items Array of items updated
 * @param {String} listId The id of the list where the items are updated
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateItemsSuccessAction(
  items: IListItem[],
  listId: string
): IUpdateItemsSuccessAction {
  return { type: types.UPDATE_ITEMS_SUCCESS, items, listId };
}

/**
 * Update item action used to inform reducer of failed ajax request to update an item
 * @return {Object} Object with type of action for reducer to handle
 * */
export function updateItemsBeginAction(): IBeginAction {
  return { type: types.UPDATE_ITEMS_BEGIN };
}

/**
 * Assign Item action used to inform reducer of failed ajax request to assign a item
 * @param {Object} error Error object with error information
 * @return {Object} Object with type of action for reducer to handle
 * */
export function assignItemsFailureAction(error: any): IActionError {
  return { type: types.ASSIGN_ITEMS_FAILURE, error };
}

export function unassignItemsFailureAction(error: any): IActionError {
  return { type: types.UNASSIGN_ITEMS_FAILURE, error };
}

export interface IAssignmentLink {
  item_id: string;
  team_id: string;
  mobile_user_id: string;
}

export interface IAssignItemsSuccessAction extends IAction {
  links: IAssignmentLink[];
}

/**
 * Assign Item action used to inform reducer of failed ajax request to assign an item
 * @param {Array} links Array of new links
 * @return {Object} Object with type of action for reducer to handle
 * */
export function assignItemsSuccessAction(
  links: IAssignmentLink[]
): IAssignItemsSuccessAction {
  return { type: types.ASSIGN_ITEMS_SUCCESS, links };
}

export interface IUnassignItemsSuccessAction extends IAction {
  itemIds?: string[];
  mobileUserIds?: string[];
  onlyUnassignMobileUsers?: boolean;
}

/**
 * Unassign Item action used to inform reducer of successful ajax request to assign an item
 * @param {Array} itemIds Array of Item id's for which we want to clear assignments (owners)
 * @return {Object} Object with type of action for reducer to handle
 * */
export function unassignItemsSuccessAction(
  ids: string[],
  onlyUnassignMobileUsers?: boolean
): IUnassignItemsSuccessAction {
  const idsValues = onlyUnassignMobileUsers
    ? {
        itemIds: undefined,
        mobileUserIds: ids,
      }
    : {
        itemIds: ids,
        mobileUserIds: undefined,
      };

  return {
    type: types.UNASSIGN_ITEMS_SUCCESS,
    ...idsValues,
    onlyUnassignMobileUsers,
  };
}
/**
 * Assign item action used to inform reducer of failed ajax request to assign an item
 * @return {Object} Object with type of action for reducer to handle
 * */
export function assignItemsBeginAction(): IBeginAction {
  return { type: types.ASSIGN_ITEMS_BEGIN };
}

/**
 * Unassign item action used to inform reducer of failed ajax request to assign an item
 * @return {Object} Object with type of action for reducer to handle
 * */
export function unassignItemsBeginAction(): IBeginAction {
  return { type: types.UNASSIGN_ITEMS_BEGIN };
}

/**
 * Action creator for setting the flag when uploading a file
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function uploadFileBeginAction(): IBeginAction {
  return {
    type: types.UPLOAD_FILE_BEGIN,
  };
}

/**
 * Action creator dispatched when there is a successful upload of file
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function uploadFileSuccessAction(): IAction {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
  };
}

/**
 * Action creator for setting the flag when uploading a file has failed
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function downloadListItemsFailureAction(error: any): IActionError {
  return {
    type: types.DOWNLOAD_LIST_ITEM_FAILURE,
    error,
  };
}

/**
 * Action creator for setting the flag when uploading a file
 * @returns {Object} Object with type of action for reducer to resolve
 */
export function downloadListItemsBeginAction(): IBeginAction {
  return {
    type: types.DOWNLOAD_LIST_ITEM_BEGIN,
  };
}

/**
 * Action creator dispatched when there is a successful upload of file
 * @returns {Object} Object with type of action for reducer to resolve and update state of store
 */
export function downloadListItemsSuccessAction(): IAction {
  return {
    type: types.DOWNLOAD_LIST_ITEM_SUCCESS,
  };
}

/**
 * Action creator for setting the flag when uploading a file has failed
 * @param {Object} error Error object of failed request
 * @returns {Object} Object with type of action for reducer to use when updating the redux store with the given values
 */
export function uploadFileFailureAction(error: any): IActionError {
  return {
    type: types.UPLOAD_FILE_FAILURE,
    error,
  };
}

export interface ISelectListActionCreator extends IAction {
  selectedList: IList;
}

/**
 * Action creator that allows the selection of a list from the store when a user selects on from the table
 * @param selectedList The selected List
 * @return {Object}
 */
export const selectListActionCreator = (
  selectedList: IList
): ISelectListActionCreator => ({
  type: types.SELECT_LIST,
  selectedList,
});

/**
 * creates a clear data action
 * @returns {Object}
 */
export function clearListDataAction(): IAction {
  return {
    type: types.CLEAR_DATA,
  };
}
export interface ISetListGeoTaggedCustomersCountAction extends IAction {
  item_nb: number;
  listId: string;
}

export function editAttributeTagBeginAction(): IBeginAction {
  return { type: types.CHANGE_LIST_ATTRIBUTE_TAG_BEGIN };
}
export function editAttributeTagFailureAction(error: any): IActionError {
  return {
    type: types.CHANGE_LIST_ATTRIBUTE_TAG_FAILURE,
    error,
  };
}

export interface IEditAttributeTagSuccessAction extends IAction {
  params: IChangeTagParams;
  listId: string;
}
export function editAttributeTagSuccessAction(
  params: IChangeTagParams,
  listId: string
): IEditAttributeTagSuccessAction {
  return {
    type: types.CHANGE_LIST_ATTRIBUTE_TAG_SUCCESS,
    params,
    listId,
  };
}
export function fetchListOptionsFailureAction(error: any): IActionError {
  return { type: types.FETCH_OPTIONS_FAILURE, error };
}
export interface IFetchListOptionsSuccessAction extends IAction {
  listId: string;
  options: { id: string; name: string }[];
}
export function fetchListOptionsSuccessAction(
  listId: string,
  options: { id: string; name: string }[]
): IFetchListOptionsSuccessAction {
  return {
    type: types.FETCH_OPTIONS_SUCCESS,
    listId,
    options,
  };
}

export interface IUnassignItemsUsingLinksAction extends IAction {
  links: TItemMobileUserLink[];
  listId?: string;
}
export function unassignItemsUsingLinksAction(
  links: TItemMobileUserLink[],
  listId: string
): IUnassignItemsUsingLinksAction {
  return {
    type: types.UNASSIGN_ITEMS_USING_LINKS,
    links,
    listId,
  };
}

export interface IAddItemsToListActionCreator extends IAction {
  items: IListItem[];
  listId: string;
}
export function addItemsToListActionCreator({
  items,
  listId,
}: IAddItemsToListActionCreator) {
  return {
    type: types.ADD_ITEMS_TO_LIST,
    items,
    listId,
  };
}
