import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../../lang";
import { getLang } from "../../../authentication/redux/selector";
import {
  fetchTablesForClientBeginAction,
  fetchTablesForClientFailureAction,
  fetchTablesForClientSuccessAction,
} from "./actionCreators";
import { fetchTablesForClientApiCall } from "./api";

export type TFetchTablesForClientActionFunc = (
  clientId: string,
  filters: any
) => IDispatchAndGetState<void>;

/**
 * Fetch all Tables Action dispatches action creators to redux store and makes api calls to fetch all tables
 * for a given client
 * @param {String} clientId Client id to fetch user tables for
 * @return {any} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const fetchTablesForClientAction: TFetchTablesForClientActionFunc = (
  clientId: string
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(fetchTablesForClientBeginAction());

    return fetchTablesForClientApiCall(clientId)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus<any>(serverResponse);
        const { tables } = data;
        dispatch(ajaxSuccessAction());
        dispatch(fetchTablesForClientSuccessAction(clientId, tables));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchTablesForClientError",
          error,
          fetchTablesForClientFailureAction,
          currLang
        );
      });
  };
};

export interface IDbSchemaActions {
  fetchDbTableAction: TFetchTablesForClientActionFunc;
}

const actions: IDbSchemaActions = {
  fetchDbTableAction: fetchTablesForClientAction,
};

export default actions;
