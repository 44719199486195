import { Box, Button } from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  BasicCloseIcon,
  BasicDialogActions,
  BasicDialogContent,
  BasicDialogTitle,
} from "components/Dialog/BasicDialog";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputText from "components/Input/InputText/InputText";
import { ITerritory } from "containers/territories/model";
import { getTerritoriesSchema } from "containers/territories/redux/selectors";
import useFormState from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { formatFormFieldsForBackend } from "utils/formatting/customFieldValues";

import InputCreateShape from "../InputShape/InputCreateShape";
import { TerritoryForm } from "../TerritoryForm/TerritoryForm";
import { getDefaultOwners } from "../utils/getDefaultOwners";

export interface ICreateTerritoryPanel {
  territoryId: string;
  ownerOptions: IOption<string>[];
  onSave?: (args: Omit<ITerritory, "shape">) => void;
  onClose: () => void;
  onCancel: () => void;
  onDraw: () => void;
}

const CreateTerritoryPanel = ({
  territoryId,
  ownerOptions,
  onSave,
  onClose,
  onCancel,
  onDraw,
}: ICreateTerritoryPanel) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.lists.subCategories.territories.createTerritoryModal;

  const territorySchema = useSelector(getTerritoriesSchema);

  const initTerritory = {
    id: territoryId,
    name: "",
    owners: [],
  } as Omit<ITerritory, "shape">;

  const formState = useFormState<ITerritory>({
    initAttributes: initTerritory,
  });

  const {
    attributes: territory,
    handleInputChange,
    handleMultipleSelectChange,
  } = formState;

  const formattedTerritory = formatFormFieldsForBackend({
    formFields: territory,
    fieldsSchema: territorySchema,
  }) as ITerritory;

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <BasicDialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {langKey.createTitle}
          <BasicCloseIcon onClick={onClose} />
        </Box>
      </BasicDialogTitle>

      <BasicDialogContent>
        <InputText
          name={"name"}
          label={langKey.inputName.title}
          defaultValue={territory.name}
          onChange={handleInputChange}
          required
        />

        <TerritoryForm formState={formState} />

        <InputCreateShape onDraw={onDraw} required />

        <InputMultipleSelect
          useDropDownMenu
          name="owners"
          onChange={handleMultipleSelectChange}
          options={ownerOptions}
          defaultValue={getDefaultOwners({
            owners: territory.owners,
            options: ownerOptions,
          })}
          langlabel={langKey.inputOwners}
          lang={lang}
        />
      </BasicDialogContent>

      <BasicDialogActions>
        <Box justifyContent="flex-start" flexGrow={1}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={onCancel}
            style={{ marginRight: "16px" }}
          >
            {lang.modal.cancel}
          </Button>

          <Button
            color="secondary"
            variant="contained"
            disableElevation
            onClick={onSave && (() => onSave(formattedTerritory))}
            disabled={!onSave || !territory.name}
          >
            {lang.modal.save}
          </Button>
        </Box>
      </BasicDialogActions>
    </div>
  );
};

export default CreateTerritoryPanel;
