import _ from "lodash";

import { IErpOrder } from "containers/erp/types";
import { IAction } from "redux/store/model";
import { updateItemsInArray } from "utils/reducerUtils";

import { IUpdateOrderStatusSuccessAction } from "./actionCreators";
import * as types from "./actionTypes";
import initialState from "./initialState";

export interface IErpOrdersState {
  orders: IErpOrder[];
  isLoading: boolean;
  isUpdating: boolean;
}

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.FETCH_ALL_ERP_ORDERS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_ALL_ERP_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.FETCH_ALL_ERP_ORDERS_SUCCESS:
      const { orders } = action as any;
      return {
        ...state,
        isLoading: false,
        orders,
      };
    case types.UPDATE_ERP_ORDER_STATUS_BEGIN:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_ERP_ORDER_STATUS_FAILURE:
      return {
        ...state,
        isUpdating: false,
      };
    case types.UPDATE_ERP_ORDER_STATUS_SUCCESS: {
      const { orders } = action as IUpdateOrderStatusSuccessAction;

      const newOrders = _.forEach(state.orders, (order) => {
        const updatedOrder = _.find(orders, { id: order.id });
        if (updatedOrder) {
          order.status = updatedOrder.status;
        }
      });

      const updatedOrder = updateItemsInArray(state.orders, newOrders);
      return {
        ...state,
        isUpdating: false,
        updatedOrder,
      };
    }
    case types.FETCH_SFA_DATA_SUCCESS: {
      const { dataKey, data } = action as any;
      return {
        ...state,
        isUpdating: false,
        [dataKey]: data,
      };
    }
    default:
      return state;
  }
}
