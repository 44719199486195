import React, { Component } from "react";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import InputText from "components/Input/InputText/InputText";
import TLang from "model/application/Lang";
import { convertNameToTag } from "utils/convertNameToTag";

export interface IMobRole {
  key: string;
  label: string;
}

export interface IInputMobRoleProps {
  onChange: (e: IMobRole) => void;
  element: IMobRole;
  editMode?: boolean;
  lang: TLang;
}

export const getErrorMessageForMobRole: TValidateFunction<IMobRole> = ({
  attributes,
  lang,
}) => {
  const { key, label } = attributes;
  const result: any = {};
  if (!key || key.length === 0)
    result["key"] = lang.components.inputErrors.empty;
  if (!label || label.length === 0)
    result["label"] = lang.components.inputErrors.empty;
  return result;
};

export class MobileUserRoleForm extends Component<
  IInputMobRoleProps,
  IMobRole
> {
  autoFillTag: React.RefObject<boolean>;
  constructor(props: IInputMobRoleProps) {
    super(props);
    const { element } = props;
    this.state = element;
    this.autoFillTag = React.createRef();
    this.autoFillTag = {
      ...this.autoFillTag,
      current: true,
    };
  }

  handleInputChange = (value: any, name: string) => {
    if (this.autoFillTag.current && name === "label" && !this.props.editMode) {
      const newTag = convertNameToTag(value);
      const newState = {
        ...this.state,
        [name]: value,
        key: newTag,
      };

      this.setState(newState);
      this.props.onChange(newState);
    }

    if (name === "key") {
      this.autoFillTag = {
        ...this.autoFillTag,
        current: false,
      };
      const newState = {
        ...this.state,
        [name]: value,
      };
      this.setState(newState);
      this.props.onChange(newState);
    }
  };

  render() {
    const { element, lang, editMode } = this.props;

    const labelToDisplay = lang.modal.createEditOption;

    return (
      <div>
        <InputText
          name="label"
          lang={labelToDisplay.inputLabel}
          defaultValue={element.label}
          onChange={this.handleInputChange}
          required={true}
        />

        <InputText
          name="key"
          lang={labelToDisplay.inputKey}
          defaultValue={element.key}
          onChange={this.handleInputChange}
          disabled={editMode}
          required={true}
          lowercase
          replaceSpaces
        />
      </div>
    );
  }
}

export default MobileUserRoleForm;
