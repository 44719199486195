import React, { ComponentProps, useState } from "react";

import { MenuList } from "@material-ui/core";
import _ from "lodash";

import ScrollEdgeListener from "components/ScrollEdgeListener";

export interface IDynamicMenuListProps {
  buildMenuItemFunction: (index: number) => React.ReactNode;
  listSize: number;
  maxItems: number;
  Component?: React.ElementType;
}

type Props<T extends React.ElementType> = IDynamicMenuListProps &
  ComponentProps<T>;

/* ----------------------- DynamicallyRenderedMenuList ----------------------- */
// A very fast and efficient way to render a large list of items in a scrollable container.
const DynamicallyRenderedMenuList = <T extends React.ElementType>({
  buildMenuItemFunction,
  listSize,
  maxItems,
  Component = MenuList, // Default to MenuList if no component is provided,
  ...rest
}: Props<T>) => {
  const [itemsToShow, setItemsToShow] = useState(Math.min(listSize, maxItems));

  return (
    <Component {...rest}>
      {_.times(itemsToShow, (index) => buildMenuItemFunction(index))}
      <ScrollEdgeListener
        callback={() => {
          if (itemsToShow < listSize) {
            setItemsToShow(Math.min(itemsToShow + maxItems, listSize));
          }
        }}
      />
    </Component>
  );
};

export default DynamicallyRenderedMenuList;
