import apiService from "api/apiService";
import ITable from "model/entities/Database";
import { IServerResponse } from "redux/actions/appActions";

const DATABASES_ENDPOINT = "db";
const SCHEMAS_ENDPOINT = "schema";

/**
 * Fetch all tables api call for a single client
 * @param {String} clientId Client Id
 * @return {Promise}
 * */
export type TFetchTablesForClientResponse = { tables: ITable[] };
export type TFetchTablesForClientFunc = (
  clientId: string
) => Promise<IServerResponse<{ tables: ITable[] }>>;
export const fetchTablesForClientApiCall: TFetchTablesForClientFunc = () => {
  return apiService.get(`${DATABASES_ENDPOINT}/${SCHEMAS_ENDPOINT}`);
};
