import React, { useState } from "react";

import { Box, BoxProps, Button, makeStyles, Popover } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import * as colors from "assets/colors";
import SmallIconButton from "components/Buttons/SmallIconButton";
import DefaultChip from "components/Chip/DefaultChip";
import { getIsUserAManager } from "containers/calendar/redux/selectors";
import { allListsSelector } from "containers/lists/redux/selectors";
import { useTranslations } from "hooks";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import { getCustomerById } from "./utils/getCustomerNameById";

export function getEventStatusColor(status?: CalendarEventStatus) {
  switch (status) {
    case CalendarEventStatus.COMPLETED:
      return colors.deliveredText;
    case CalendarEventStatus.OUTDATED:
      return colors.draftText;
    case CalendarEventStatus.PENDING:
      return colors.waitingText;
    case CalendarEventStatus.ONGOING:
    case CalendarEventStatus.PLANNED:
      return colors.confirmedText;
    case CalendarEventStatus.DECLINED:
    case CalendarEventStatus.CANCELLED:
    case CalendarEventStatus.DELETED:
    case CalendarEventStatus.ARCHIVED:
      return colors.cancelledText;
    default:
      return colors.GreyDark;
  }
}

export function getEventFillColor(status?: CalendarEventStatus) {
  switch (status) {
    case CalendarEventStatus.COMPLETED:
      return colors.delivered;
    case CalendarEventStatus.OUTDATED:
      return makeColorTransparent(colors.draft, 0.6);
    case CalendarEventStatus.PENDING:
      return colors.waiting;
    case CalendarEventStatus.ONGOING:
    case CalendarEventStatus.PLANNED:
      return colors.confirmed;
    case CalendarEventStatus.DECLINED:
    case CalendarEventStatus.CANCELLED:
    case CalendarEventStatus.DELETED:
    case CalendarEventStatus.ARCHIVED:
      return colors.cancelled;
    default:
      return colors.GreyDark;
  }
}

export interface IBoardItemProps {
  event: TCalendarEvent;
  onEditEvent: (event: TCalendarEvent) => void;
  onClickDelete: (event: TCalendarEvent) => void;
  onClickApproveEvent?: (event: TCalendarEvent) => void;
  onClickDeclineEvent?: (event: TCalendarEvent) => void;
  boxProps?: BoxProps;
  active?: boolean;
  boardOrientation?: "horizontal" | "vertical";
}

interface IStyleProps {
  status: CalendarEventStatus;
  active?: boolean;
  small?: boolean;
  large?: boolean;
  medium?: boolean;
  orientation?: "horizontal" | "vertical";
}
const useStyles = makeStyles({
  container: ({ status, active, large }: IStyleProps) => ({
    backgroundColor: active
      ? colors.PaleYellowOrange
      : getEventFillColor(status),
    boxShadow: "0px 1px 3px 0px rgba(18, 78, 93, 0.15);",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: active ? colors.PaleYellowOrange : colors.OffWhiteLight,
    },
    height: "100%",
    // minWidth: "150px",
    paddingLeft: !large ? undefined : "4px",
    width: "92%",
    padding: !large ? undefined : "8px",
    borderRadius: "4px",
    overflow: "hidden",
  }),
  pad: ({ status, active, small, orientation }: IStyleProps) => ({
    borderLeft: active
      ? `3.5px solid ${colors.Mustard}`
      : `3.5px solid ${getEventStatusColor(status)}`,
    height: "100%",
    paddingLeft: small ? "2px" : "8px",
    display: "flex",
    flexDirection: small && orientation == "vertical" ? "row" : "column",
    alignContent: "left",
    justifyItems: "center",
    columnGap: small ? "16px" : "0px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "left",
    alignItems: small ? "center" : undefined,
  }),
  heading: ({ small }: IStyleProps) => ({
    display: "grid",
    gridTemplateColumns: small ? "auto" : "auto minmax(auto,25px)",
    flexShrink: 0,
  }),
  timeSlot: ({ orientation }: IStyleProps) => ({
    fontWeight: 500,
    color: colors.GreyDark,
    fontSize: orientation === "horizontal" ? "11px" : "12px",
    textAlign: "left",
  }),
  content: ({ small, orientation }: IStyleProps) => ({
    flexGrow: 2,
    alignItems: "start",
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: small ? (orientation === "horizontal" ? "12px" : "14px") : "16px",
    textWrap: orientation === "horizontal" && small ? undefined : "nowrap",
    paddingRight: "8px",
    maxWidth: small ? "95px" : "unset",
  }),
});

const BoardItem: React.FC<Readonly<IBoardItemProps>> = ({
  event,
  boxProps,
  onEditEvent,
  onClickDelete,
  onClickApproveEvent,
  onClickDeclineEvent,
  active,
  boardOrientation = "vertical",
  children,
}) => {
  const lang = useTranslations();
  const isManager = useSelector(getIsUserAManager);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const { moment } = useMomentTimeZone();
  const small = moment(event.end_time).diff(event.start_time, "minutes") <= 30;
  const medium =
    !small && moment(event.end_time).diff(event.start_time, "minutes") <= 60;
  const large = !small && !medium;
  const status = event.status || CalendarEventStatus.PLANNED;
  const allLists = useSelector(allListsSelector);
  const classes = useStyles({
    status,
    active,
    small,
    medium,
    large,
    orientation: boardOrientation,
  });

  const dateString = moment(event.start_time).format("dddd, Do MMMM");
  const customerName = getCustomerById(allLists, event.customer_id)?._name;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleClickEdit() {
    onEditEvent(event);
    handleClose();
  }
  function handleClickDelete() {
    onClickDelete(event);
    handleClose();
  }
  function handleClickApprove() {
    onClickApproveEvent?.(event);
    handleClose();
  }
  function handleClickDecline() {
    onClickDeclineEvent?.(event);
    handleClose();
  }
  const startTime = moment(event.start_time).format("hh:mm");
  const endTime = moment(event.end_time).format(`hh:mm ${small ? "" : "A"}`);
  const eventTimeSlotString = `${startTime}-${endTime}`;
  const id = open ? "simple-popover" : undefined;

  const statusColor = getEventStatusColor(status);
  const fillColor = getEventFillColor(status);
  return (
    <>
      <Box
        key={String(small)} // to force re-render specially for placeholder boarditems
        onClick={handleClick}
        className={classNames(
          classes.container,
          _.pick(boxProps, ["className"])
        )}
        {..._.omit(boxProps, ["className", "style"])}
        style={{
          alignItems: small ? "center" : "left",
          ...(boxProps?.style || {}),
        }}
      >
        <Box className={classes.pad}>
          {children ? (
            children
          ) : (
            <>
              <Box className={classes.heading} data-testid="heading">
                <Box className={classes.timeSlot}>{eventTimeSlotString}</Box>
              </Box>
              <Box className={classes.content}>{event.title}</Box>
            </>
          )}
        </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: `0px 6px 10px 0px ${colors.GreyLight}`,
            paddingBottom: "6px",
          },
        }}
      >
        <Box minWidth={"300px"}>
          <Box
            gridTemplateRows={"auto 1fr"}
            display={"grid"}
            padding={"6px 12px"}
          >
            <Box display={"flex"} alignItems={"center"} gridColumnGap={16}>
              <Box
                flexGrow={2}
                display={"flex"}
                alignItems={"center"}
                gridColumnGap={5}
              >
                <Box
                  maxWidth={"200px"}
                  fontSize={16}
                  fontWeight={500}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                >
                  {event.title}
                </Box>
                <Box>
                  <DefaultChip
                    label={_.upperFirst(
                      _.lowerCase(
                        _.get(
                          lang.containers.calendar.subCategories.calendar
                            .createEditModal.inputStatus.options,
                          status,
                          event.status
                        )
                      )
                    )}
                    style={{
                      background: statusColor,
                      color: fillColor,
                      height: 20,
                      fontSize: 12,
                    }}
                  />
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"end"}>
                {
                  //@ts-ignore
                  status != CalendarEventStatus.COMPLETED && (
                    <SmallIconButton onClick={handleClickEdit}>
                      <EditIcon htmlColor={colors.GreyDark} fontSize="small" />
                    </SmallIconButton>
                  )
                }
                <SmallIconButton onClick={handleClickDelete}>
                  <DeleteIcon htmlColor={colors.red} fontSize="small" />
                </SmallIconButton>
                <SmallIconButton onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </SmallIconButton>
              </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} paddingTop={"16px"}>
              {customerName && (
                <Box fontWeight={"500"} fontSize={"16px"}>
                  {customerName}
                </Box>
              )}
              <Box
                color={colors.GreyDark}
                fontSize={14}
                fontWeight={500}
              >{`${dateString} ${eventTimeSlotString}`}</Box>
              <Box>{event.description}</Box>
              {[
                CalendarEventStatus.PENDING,
                CalendarEventStatus.DECLINED,
              ].includes(status) &&
                isManager && (
                  <Box
                    display={"flex"}
                    justifyContent={"right"}
                    gridColumnGap={8}
                    paddingTop={"16px"}
                  >
                    <Button
                      color="secondary"
                      variant="outlined"
                      disableElevation
                      onClick={handleClickDecline}
                      disabled={status === CalendarEventStatus.DECLINED}
                    >
                      {_.upperFirst(lang.genericTerms.decline)}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      disableElevation
                      onClick={handleClickApprove}
                    >
                      {_.upperFirst(lang.genericTerms.accept)}
                    </Button>
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default BoardItem;

function makeColorTransparent(hexColor: string, opacity: number): string {
  const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  if (!colorRegex.test(hexColor)) {
    return hexColor;
  }
  const color = hexColor.replace("#", "");
  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);
  const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return rgbaColor;
}
