import { Box, makeStyles } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import StoreIcon from "@mui/icons-material/Store";
import _ from "lodash";
import moment from "moment";

import { IAugmentedActivityReport } from "containers/workflows/redux/api";
import { TTimelineData } from "model/entities/Dashboard";

import { getActionIcon } from "./getActionIcon";
import styles from "./styles";
import TimelineItemContent from "./TimelineItemContent";

const useStyles = makeStyles(styles);

export interface ITimelineCardProps {
  reports?: IAugmentedActivityReport[];
  data?: TTimelineData[];
  title: string | JSX.Element;
  onClickReport?: (customer_id: string) => void;
}
function TimelineCard({
  data: _data,
  title,
  onClickReport,
  reports,
  ...rest
}: ITimelineCardProps) {
  const data = _.uniqWith(_data, _.isEqual);
  const classes = useStyles();

  function timelineDataToTimelineSteps() {
    return _.map(data, function (step: TTimelineData, index) {
      const icon = getActionIcon(step.action);
      return (
        <TimelineItem
          key={`${step.customer_id}-${index}-${step.customer_name}`}
          className={classes.timelineItem}
          data-testid="timeline-step"
        >
          <TimelineOppositeContent className={classes.time}>
            <div title={step.date.toString()}>
              {moment(step.date).format("hh:mm:ss A")}
            </div>
          </TimelineOppositeContent>

          <TimelineSeparator className={classes.separator}>
            {icon ? (
              <icon.type {...icon.props} className={classes.icon} />
            ) : null}
            {data?.length && index < data?.length - 1 ? (
              <TimelineConnector />
            ) : null}
          </TimelineSeparator>

          <TimelineContent className={classes.action}>
            <TimelineItemContent
              reports={reports}
              step={step}
              onClickReport={onClickReport}
            />
          </TimelineContent>
        </TimelineItem>
      );
    });
  }

  return (
    <Box data-testid="timeline-card" className={classes.container} {...rest}>
      <Box className={classes.cardbody}>
        <Box className={classes.titleSection} gridArea={"title"}>
          {title}
        </Box>
        <Timeline
          data-testid="timeline"
          align="left"
          className={classes.timeline}
        >
          {timelineDataToTimelineSteps()}
        </Timeline>
      </Box>
      <Box gridArea={"icon"} className={classes.sideBarIconContainer}>
        <StoreIcon className={classes.sideBarIcon} />
      </Box>
      <Box gridArea={"line"} className={classes.line}></Box>
    </Box>
  );
}

export default TimelineCard;
