export function getRandomNsizedDigit(range: number) {
  const rangeStart = 10 ** (range - 1);
  const rangeEnd = 10 ** range - 1;
  const number = Math.floor(
    Math.random() * (rangeEnd - rangeStart) + rangeStart
  );
  return number;
}

interface IConvertNameToTagOptions {
  prefix?: string;
}

export function convertNameToTag(
  name: string,
  options?: IConvertNameToTagOptions
): string {
  const { prefix = "" } = options || {};

  if (!name) {
    return "";
  }
  let result = name;

  result = result.replaceAll(" ", "_");
  result = result.toLowerCase();
  result = result.replace(/[^\w\s]/gi, "_");

  return prefix + result;
}
