import { Box, makeStyles } from "@material-ui/core";
import MotionPhotosAutoOutlinedIcon from "@mui/icons-material/MotionPhotosAutoOutlined";
import _ from "lodash";
import moment from "moment";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { GreyDark } from "assets/colors";
import { allListsSelector } from "containers/lists/redux/selectors";
import { useTranslations } from "hooks";

import { IBoardProps } from "./Board";
import BoardItem from "./BoardItem";
import { computeEventsIndexes } from "./utils/computeEventsIndexes";
import { getCustomerById } from "./utils/getCustomerNameById";

const BOARD_ITEM_HEIGHT = 121;

export interface IStackBoardProps
  extends Pick<
    IBoardProps,
    | "onClickApproveEvent"
    | "onClickDeclineEvent"
    | "onClickDeleteEvent"
    | "onClickTimeSlot"
    | "onEditEvent"
    | "boardId"
    | "events"
    | "activeBoardItems"
    | "eventPlaceHolders"
  > {}

const useStyles = makeStyles({
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #F7F9FA",
  },
  boardSlot: {
    border: `1px solid #F7F9FA`,
    width: "100%",
  },
  slot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #F7F9FA",
    borderTop: "1px solid #F7F9FA",
    height: BOARD_ITEM_HEIGHT,
    borderRight: "1px solid #F7F9FA",
  },
  slotInner: {
    transition: "0.3s",
    height: "90%",
    width: "90%",
    cursor: "pointer",
    borderRadius: "4px",
  },
  boardItem: {
    height: BOARD_ITEM_HEIGHT,
    width: "100%",
    display: "flex",
    padding: "13px 8px",
    position: "relative",
  },
  boardItemInner: {
    position: "absolute",
    width: "100%",
    height: BOARD_ITEM_HEIGHT - 22,
    zIndex: 3,
  },
  placeholder: {
    height: BOARD_ITEM_HEIGHT,
    width: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    padding: "13px 8px",
    opacity: 0.5,
  },
  fullHeight: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100vh",
  },
  icon: {
    fontSize: "16px",
  },
  label: {
    fontSize: "12px",
    color: GreyDark,
  },
  customerName: {
    fontSize: "16px",
    fontWeight: 700,
  },
});

const StackedBoard: React.FC<IStackBoardProps> = ({
  onClickDeleteEvent,
  onEditEvent,
  onClickApproveEvent,
  onClickDeclineEvent,
  onClickTimeSlot,
  eventPlaceHolders,
  activeBoardItems,
  boardId,
  events: _events,
}) => {
  const lang = useTranslations();
  const langKey = lang.containers.calendar.subCategories.calendar;
  const classes = useStyles();
  const events = computeEventsIndexes(_events);
  const allLists = useSelector(allListsSelector);

  return (
    <Droppable droppableId={boardId} key={boardId}>
      {(provided) => (
        <div
          className={classes.container}
          ref={provided.innerRef}
          {...provided.droppableProps}
          data-testid={`board-${boardId}`}
        >
          {_(events)
            .map((e, index) => {
              const customerName = getCustomerById(
                allLists,
                e.customer_id
              )?._name;
              return (
                <Draggable
                  key={String(e.id) + index}
                  draggableId={e.id as string}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Box key={e.id} className={classes.boardItem}>
                        <Box className={classes.boardItemInner}>
                          <BoardItem
                            event={e}
                            onClickDelete={onClickDeleteEvent}
                            onClickApproveEvent={onClickApproveEvent}
                            onClickDeclineEvent={onClickDeclineEvent}
                            onEditEvent={onEditEvent}
                            active={_.includes(activeBoardItems, e.id)}
                          >
                            <BoardItemBody
                              showIcon={_.lowerCase(e.created_by) === "system"}
                              customerName={customerName || ""}
                              label={`${_.upperFirst(
                                langKey.createEditModal.inputVisit.customMessage
                                  .stop
                              )} ${index}`}
                            />
                          </BoardItem>
                        </Box>
                      </Box>
                    </div>
                  )}
                </Draggable>
              );
            })
            .value()}

          {provided.placeholder}

          {_.map(eventPlaceHolders, (event, index) => {
            return (
              <Box
                key={String(index) + "_placeholder"}
                className={classes.placeholder}
              >
                <BoardItem
                  event={event as any}
                  onClickDelete={() => {}}
                  onEditEvent={() => {}}
                >
                  <BoardItemBody
                    showIcon={false}
                    customerName={event.customerName ?? ""}
                    label=""
                  />
                </BoardItem>
              </Box>
            );
          })}

          <Box
            height={`auto`}
            minHeight={`calc(100% - ${events.length * BOARD_ITEM_HEIGHT}px)`}
            width={"100%"}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: `100vh`,
            }}
          >
            {_.times(Math.ceil(window.innerHeight / BOARD_ITEM_HEIGHT), (i) => {
              return (
                <Box
                  key={i}
                  className={classes.slot}
                  onClick={
                    onClickTimeSlot
                      ? () => onClickTimeSlot(moment(boardId))
                      : undefined
                  }
                >
                  <Box className={classes.slotInner}></Box>
                </Box>
              );
            })}
          </Box>
        </div>
      )}
    </Droppable>
  );
};

export default StackedBoard;

interface IBoardItemBody {
  showIcon?: boolean;
  customerName: string;
  label: string;
}
const BoardItemBody: React.FC<IBoardItemBody> = ({
  showIcon,
  customerName,
  label,
}) => {
  return (
    <Box display={"grid"} gridTemplateRows={"2fr 1fr"} paddingLeft={"8px"}>
      <Box
        display={"grid"}
        gridTemplateColumns={showIcon ? "16px 1fr" : "auto"}
        gridColumnGap={"4px"}
      >
        <Box>
          {showIcon && (
            <MotionPhotosAutoOutlinedIcon
              style={{
                fontSize: "16px",
              }}
              htmlColor={GreyDark}
            />
          )}
        </Box>
        <Box fontSize={"12px"} color={GreyDark}>
          {label}
        </Box>
      </Box>
      <Box fontSize={"16px"} fontWeight={700}>
        {customerName}
      </Box>
    </Box>
  );
};
