import { IVisitEvent, TCalendarEvent } from "fieldpro-tools";
import Moment from "moment";

export function getEventRowPosition(
  event: IVisitEvent,
  _calendarstartTime: moment.Moment | Date,
  moment: typeof Moment
) {
  const startOffSet = Math.floor(moment(event.start_time).minutes() / 30);
  const startDiffFromStartTime = moment(event.start_time).diff(
    moment(event?.start_time).startOf("day"),
    "hours"
  );

  const duration =
    Math.ceil(moment(event.end_time).diff(event.start_time, "minutes") / 30) *
    30;
  const durationNormalized = Math.floor(duration / 30);

  //prettier-ignore
  return `${(startDiffFromStartTime * 2 + 1) +  startOffSet} / span ${durationNormalized}`;
}

export function getEventColPosition(
  event: IVisitEvent,
  calendarstartTime: moment.Moment | Date,
  moment: typeof Moment
) {
  const startOffSet = Math.floor(moment(event.start_time).minutes() / 60);
  const startDiffFromStartTime = moment(event.start_time).diff(
    calendarstartTime,
    "hours"
  );

  const duration =
    Math.ceil(moment(event.end_time).diff(event.start_time, "minutes") / 60) *
    60;
  const durationNormalized = Math.floor(duration / 60);

  //prettier-ignore
  return `${(startDiffFromStartTime + 1) +  startOffSet} / span ${durationNormalized}`;
}

export function calculateMarginTop(event: any, moment: typeof Moment) {
  const minuteOffset = moment(event.start_time).minutes() % 30;
  const pixelsPerMinute = 0.5;
  return minuteOffset * pixelsPerMinute;
}

export function getMaxEventWidth(event: TCalendarEvent, moment: typeof Moment) {
  const cellWidth = 160;
  const duration = moment(event.end_time).diff(event.start_time, "minutes");
  const pixelPerMinute = cellWidth / 60;
  const maxWidth = duration * pixelPerMinute;
  return maxWidth;
}
export function getEventMarginLeft(
  event: TCalendarEvent,
  moment: typeof Moment
) {
  const cellWidth = 160;
  const pixelPerMinute = cellWidth / 60;
  const margin = moment(event.start_time).minutes() * pixelPerMinute;
  return Math.floor(margin);
}
