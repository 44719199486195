import _ from "lodash";

import { TIMELINE_EVENT_TYPE, TTimelineData } from "model/entities/Dashboard";

/**
 * @param events
 * @returns Events ordered by:
 * - first, "group" reports by visit_id (in case 2 visits were done at the same customer on the same day)
 * - then, make sure to wrap all reports between check-in and check-out
 * - finally, sort reports by timestamp
 */
const orderTimelineEvents = (events?: TTimelineData[]) => {
  return _.orderBy(events, ["visit_id", checkInCheckOutOrder, "timestamp"]);
};

const checkInCheckOutOrder = (event: TTimelineData) => {
  if (event.action === TIMELINE_EVENT_TYPE.CHECK_IN) {
    return -1;
  }
  if (event.action === TIMELINE_EVENT_TYPE.CHECK_OUT) {
    return +1;
  }
  return 0;
};

export default orderTimelineEvents;
