import { TTimelineData } from "model/entities/Dashboard";

import orderTimelineEvents from "./orderTimelineEvents";
import { sortVisitChunksByDate } from "./sortVisitChunksByDate";
import { splitByCheckInOut } from "./splitByCheckInOut";

export function getOrderedTimeLineData(data: TTimelineData[]) {
  //1. order the events by visit_id -> checkin -> <any> -> checkout -> visit_id -> ...
  //2. split the events into chunks everytime theres a checkout
  //3. sort the chunks by the checkin date

  //with step 2 + 3 we ensure that visits to the same customer on different dates arent on the same line
  return sortVisitChunksByDate(splitByCheckInOut(orderTimelineEvents(data)));
}
