import { Grid } from "@material-ui/core";
import _ from "lodash";

import { IGalleryPicture } from "./PictureGallery";
import PictureGalleryPicture from "./PictureGalleryPicture";

interface IPictureGalleryList {
  pictures: IGalleryPicture[];
  onImageClick: (tag: string, index: number) => void;
  selectedLabel?: string;
}

function PictureGalleryList({
  pictures,
  selectedLabel,
  onImageClick,
}: IPictureGalleryList) {
  const picturesList = _.map(pictures, (picture, index) => {
    return selectedLabel === picture.label || !selectedLabel ? (
      <Grid
        item
        xs={6}
        key={index}
        onClick={() => {
          onImageClick(picture.label, index);
        }}
      >
        <PictureGalleryPicture
          width="320px"
          tag={picture.label}
          url={picture.url}
        />
      </Grid>
    ) : null;
  });

  return (
    <Grid
      style={{
        rowGap: "20px",
        justifyItems: "stretch",
        minHeight: "250px",
      }}
      container
    >
      {picturesList}
    </Grid>
  );
}

export default PictureGalleryList;
