import React, { Component } from "react";

import { Avatar, TableRow as MaterialTableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import classNames from "classnames";
import { STEP_STATUS } from "fieldpro-tools";
import _ from "lodash";
import { Redirect } from "react-router-dom";

import { GreyDark } from "assets/colors";
import CustomButton from "components/Buttons/CustomButton";
import { IInputMultipleTextValue } from "components/Input/InputMultipleText/InputMultipleText";
import StepProgressBar from "components/Progress/StepProgressBar";
import CustomCheckBox from "components/SelectControls/CustomCheckBox";
import CustomSwitchControl from "components/SelectControls/CustomSwitchControl";
import {
  TABLE_COLUMN_TYPE,
  TColumnType,
  TRowAction,
} from "components/Table/model";
import TLang from "model/application/Lang";
import { ITableAction } from "model/application/Table";
import { ACTION_TYPE } from "model/application/UIActionTypes";
import { formatNumber } from "utils/numbers/formatNumber";

import BooleanCell from "../Cell/BooleanCell";
import DateCell from "../Cell/DateCell";
import DateTimeCell from "../Cell/DateTimeCell";
import FileCell from "../Cell/FileCell";
import GPSCell from "../Cell/GPSCell";
import MatrixCell from "../Cell/MatrixCell";
import OptionsCell, { getOptionsFromLabelList } from "../Cell/OptionsCell";
import PictureCell from "../Cell/PictureCell";
import RatingCell from "../Cell/RatingCell";
import SignatureCell from "../Cell/SignatureCell";
import TimeCell from "../Cell/TimeCell";
import styles from "../styles";
import CustomTableActionRow from "./CustomTableActionRow";
import CustomTableCell from "./CustomTableCell";
import {
  checkIfActive,
  cleanedRowItem,
  getRowId,
} from "./CustomTableRow.utils";

export interface ICustomTableRowProps {
  classes?: any;
  lang: TLang;
  attrToHide?: string[];
  columnTypes: TColumnType[];
  rowItem: any;
  onDetailItem?: TRowAction;
  onStopExecution?: TRowAction;
  onResumeExecution?: TRowAction;
  onContinueExecution?: TRowAction;
  onArchiveItem?: TRowAction;
  onDeleteItem?: TRowAction;
  onRestoreItem?: TRowAction;
  onReplicateItem?: TRowAction;
  onEditItem?: TRowAction;
  onViewItem?: TRowAction;
  extraActions?: ITableAction[];
  onClick: TRowAction;
  onCheckChanged?: any;
  onSwitchChanged?: any;
  hasCheckBox?: boolean;
  checked?: boolean;
  clickable?: boolean;
  onOpenPicture?: any;
  onClickItemRow?: any;
  onOpenGPS?: any;
  isFooterRow?: boolean;
  columnsForBorder?: number;
  progressData?: any[];
  toggleCollapse?: any;
  isCollapsed?: any;
  withCollapsedRows?: number;
  scrollValue?: {
    x: number;
    y: number;
  };
}

interface ICustomTableRowStates {
  cellProperties?: any;
  redirectTo?: string | null;
  isHovered: boolean;
}

/**
 * TableRow stateless component. A Single Table row
 * @param {Object} Props passed in to Table row
 */
class CustomTableRow extends Component<
  ICustomTableRowProps,
  ICustomTableRowStates
> {
  public static defaultProps = {
    columnTypes: [],
    onClick: () => true,
    progressData: [],
  };
  ref: any;
  constructor(props: ICustomTableRowProps) {
    super(props);
    this.ref = React.createRef();

    this.state = {
      redirectTo: null,
      isHovered: false,
      cellProperties: {},
    };
  }

  /**
   * On Click event callback. this tracks click event and returns event to passed in props
   * */
  onCellClick = () => {
    const { onClick, rowItem, onDetailItem } = this.props;
    if (
      !rowItem.hasOwnProperty("isClickDisabled") ||
      !rowItem.isClickDisabled
    ) {
      if (onClick) {
        onClick(rowItem);
      }
      if (onDetailItem) {
        onDetailItem(rowItem);
      }
    }
  };

  mouseEntering = () => {
    this.setState({
      ...this.state,
      isHovered: true,
    });
  };

  mouseLeaving = () => {
    this.setState({
      ...this.state,
      isHovered: false,
    });
  };

  handleStopExecution = () => {
    const { onStopExecution, rowItem } = this.props;
    if (onStopExecution) onStopExecution(rowItem);
  };

  handleResumeExecution = () => {
    const { onResumeExecution, rowItem } = this.props;
    if (onResumeExecution) onResumeExecution(rowItem);
  };

  handleContinueExecution = () => {
    const { onContinueExecution, rowItem } = this.props;
    if (onContinueExecution) onContinueExecution(rowItem);
  };

  handleExtraAction = (action: TRowAction) => {
    const { rowItem } = this.props;
    action(rowItem.id);
  };
  handleEditItem = () => {
    const { onEditItem, rowItem } = this.props;
    if (onEditItem) onEditItem(rowItem);
  };
  handleArchiveItem = () => {
    const { onArchiveItem, rowItem } = this.props;
    if (onArchiveItem) onArchiveItem(rowItem);
  };
  handleDeleteItem = () => {
    const { onDeleteItem, rowItem } = this.props;
    if (onDeleteItem) onDeleteItem(rowItem);
  };
  handleRestoreItem = () => {
    const { onRestoreItem, rowItem } = this.props;
    if (onRestoreItem) onRestoreItem(rowItem);
  };

  handleViewItem = () => {
    const { onViewItem, rowItem } = this.props;
    if (onViewItem) onViewItem(rowItem);
  };
  handleReplicateItem = () => {
    const { onReplicateItem, rowItem } = this.props;
    if (onReplicateItem) onReplicateItem(rowItem);
  };

  /**
   * Handles the check changed
   * @param {Boolean} isChecked Whether the Table row is checked
   */
  handleCheckChanged = (isChecked: boolean): void => {
    const { onCheckChanged, rowItem } = this.props;
    const rowId = getRowId(rowItem);
    onCheckChanged(rowId, isChecked);
  };

  /**
   * Handles the switch control component
   * @param {Boolean} isOn Boolean value indicating whether the switch is on or off
   */
  handleSwitchChanged = (isOn: boolean): void => {
    const { onSwitchChanged, rowItem } = this.props;
    onSwitchChanged(rowItem, isOn);
  };
  handleOpenUploadedFile = (link: any) => {
    return window.open(link, "_blank");
  };

  handleLicensedItem = () => {
    const { rowItem } = this.props;
    this.handleSwitchChanged(!rowItem.licensed);
  };

  /** Create table data cells */
  createTableDataCells = (): any => {
    const {
      rowItem,
      hasCheckBox,
      checked,
      onOpenPicture,
      onOpenGPS,
      columnsForBorder,
      attrToHide,
      columnTypes,
      isFooterRow,
      classes,
      progressData,
      withCollapsedRows,
      scrollValue,
    } = this.props;

    const { isHovered } = this.state;

    const dataCells = [];

    let isGrey = !checkIfActive(rowItem);

    if (hasCheckBox) {
      if (isFooterRow) {
        dataCells.push(
          <TableCell key="checked" className={classes.TableCell} />
        );
      } else {
        dataCells.push(
          <TableCell
            key="checked"
            className={classNames(
              classes.TableCell,
              checked && "checked",
              classes.stickyTry
            )}
            onClick={onClickStopPropagation}
            style={{
              opacity: isGrey ? "0.5" : "1",
              position: "sticky",
              zIndex: 1, // hide boolean toggles and rating stars when scrolling
              left: 1,
              backgroundColor: isHovered || checked ? "inherit" : "#fff",
            }}
          >
            <CustomCheckBox
              isChecked={checked ? true : false}
              onChecked={this.handleCheckChanged}
            />
          </TableCell>
        );
      }
    }

    if (rowItem.hasOwnProperty("_active")) {
      if (!rowItem._active) {
        isGrey = true;
      }
    }
    const style = {
      opacity: isGrey ? "0.5" : "1",
    };

    const getClassName = (value: any) => {
      const statusLabel =
        this.props.lang.containers.dashboards.subCategories.dashboards
          .statusType;
      const statusMappings = {
        //workflow steps status
        ONGOING: classes.StatusOngoingButton,
        DONE: classes.StatusDoneButton,
        CANCELLED: classes.StatusCancelledButton,
        WARNING: classes.StatusWarningButton,
        FAILURE: classes.StatusFailureButton,
        SKIPPED: classes.StatusFailureButton,
        TO_SKIP: classes.StatusFailureButton,
        //dashboards status
        [statusLabel.DRAFT]: classes.StatusDraft,
        [statusLabel.PUBLISHED]: classes.StatusPublished,
      };

      if (!value) {
        return;
      }

      return statusMappings[value] || statusMappings["ONGOING"];
    };

    // deals with normal attributes of the object to display
    dataCells.push(
      cleanedRowItem(rowItem, attrToHide, columnTypes).map(
        (
          cell: {
            columnName: string;
            columnType: string;
            value: any;
          },
          index: number
        ) => {
          let cellContent;
          switch (cell.columnType) {
            case TABLE_COLUMN_TYPE.DATE: {
              cellContent = <DateCell date={cell.value} />;
              break;
            }
            case TABLE_COLUMN_TYPE.TIME: {
              cellContent = <TimeCell date={cell.value} />;
              break;
            }
            case TABLE_COLUMN_TYPE.DATE_TIME: {
              cellContent = <DateTimeCell date={cell.value} />;
              break;
            }
            case TABLE_COLUMN_TYPE.USER: {
              const valueInitial = _.map(_.split(cell.value, " "), (word) =>
                _.upperFirst(_.head(word))
              ).join("");
              cellContent = (
                <div
                  style={{
                    display: "flex",
                    columnGap: "5px",
                    color: GreyDark,
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "20px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    style={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "24px",
                      height: "24px",
                    }}
                  >
                    {valueInitial.slice(0, 2)}
                  </Avatar>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {cell.value}
                  </span>
                </div>
              );
              break;
            }
            case TABLE_COLUMN_TYPE.GPS: {
              cellContent = (
                <GPSCell
                  coordinates={cell.value}
                  onClick={(coordinates: any) => onOpenGPS(coordinates)}
                />
              );
              break;
            }
            case TABLE_COLUMN_TYPE.SINGLE_CHOICE:
            case TABLE_COLUMN_TYPE.SINGLE_CHOICE_ON_LIST: {
              const options = getOptionsFromLabelList([cell.value]);
              cellContent = <OptionsCell options={options} />;
              break;
            }
            case TABLE_COLUMN_TYPE.MULTIPLE_CHOICE:
            case TABLE_COLUMN_TYPE.MULTIPLE_CHOICE_ON_LIST: {
              const options = getOptionsFromLabelList(cell.value);
              cellContent = <OptionsCell options={options} />;
              break;
            }
            case TABLE_COLUMN_TYPE.MULTIPLE_INPUT_TEXT:
            case TABLE_COLUMN_TYPE.MULTIPLE_INPUT_INTEGER:
            case TABLE_COLUMN_TYPE.MULTIPLE_INPUT_DECIMAL: {
              cellContent = formatMultipleInputValue(cell.value);
              break;
            }
            case TABLE_COLUMN_TYPE.CUSTOM: {
              cellContent = cell.value;
              break;
            }

            case TABLE_COLUMN_TYPE.MATRIX_ON_LIST: {
              cellContent = (
                <MatrixCell
                  title={rowItem.displayed_name}
                  matrixData={cell.value}
                />
              );
              break;
            }
            case TABLE_COLUMN_TYPE.RATING: {
              cellContent = <RatingCell value={cell.value} />;
              break;
            }
            case TABLE_COLUMN_TYPE.PICTURE: {
              cellContent = (
                <PictureCell
                  link={cell.value?.url}
                  onClick={() => onOpenPicture(cell.value?.url)}
                />
              );
              break;
            }
            case TABLE_COLUMN_TYPE.SIGNATURE: {
              const link = cell?.value?.url;
              cellContent = (
                <SignatureCell
                  link={link}
                  onClick={() => onOpenPicture(link)}
                />
              );
              break;
            }
            case TABLE_COLUMN_TYPE.UPLOAD: {
              const link = cell?.value?.url;
              cellContent = (
                <FileCell
                  link={link}
                  onClick={() => this.handleOpenUploadedFile(link)}
                />
              );
              break;
            }
            case TABLE_COLUMN_TYPE.LINK: {
              cellContent = !_.isEmpty(cell.value) && (
                <a href={cell.value} target="_blank" rel="noopener noreferrer">
                  Link
                </a>
              );
              break;
            }
            case TABLE_COLUMN_TYPE.BOOLEAN: {
              cellContent = <BooleanCell value={cell.value} />;
              break;
            }
            case TABLE_COLUMN_TYPE.SWITCH: {
              if (isFooterRow) {
                cellContent = "";
              } else {
                cellContent = (
                  <CustomSwitchControl
                    isChecked={cell.value}
                    onSwitch={this.handleSwitchChanged}
                    onClick={(event) => event.stopPropagation()}
                  />
                );
              }
              break;
            }
            case TABLE_COLUMN_TYPE.PERCENTAGE: {
              cellContent = (
                <div>
                  <div style={{ width: 100 }}>
                    <div
                      style={{
                        width: cell.value,
                        height: "1.25rem",
                        backgroundColor: cell.value ? "green" : "red",
                        float: cell.value ? "left" : "right",
                      }}
                    ></div>
                  </div>
                  <div style={{ float: "right" }}>{cell.value}%</div>
                </div>
              );
              break;
            }
            case TABLE_COLUMN_TYPE.PROGRESS: {
              if (!progressData) {
                cellContent = <div>ERROR: progressData should be set</div>;
              } else {
                cellContent = (
                  <div>
                    <div style={{ width: 100 }}>
                      <div
                        style={{
                          width: progressData[cell.columnName]
                            ? ((cell.value -
                                progressData[cell.columnName].min) /
                                (progressData[cell.columnName].max -
                                  progressData[cell.columnName].min)) *
                              100
                            : 0,
                          height: "1.25rem",
                          backgroundColor: "blue",
                          float: "left",
                        }}
                      ></div>
                    </div>
                    <div style={{ float: "right" }}>{cell.value}</div>
                  </div>
                );
              }
              break;
            }
            case TABLE_COLUMN_TYPE.STATUS: {
              cellContent = (
                <div className={getClassName(cell.value)}>{cell.value}</div>
              );
              break;
            }
            case TABLE_COLUMN_TYPE.STEPS: {
              const steps = cell.value;
              const stepCompleted = _.filter(steps, {
                step_status: STEP_STATUS.DONE,
              });
              cellContent = (
                <StepProgressBar
                  currentValue={_.size(stepCompleted)}
                  max={_.size(steps)}
                />
              );
              break;
            }
            case TABLE_COLUMN_TYPE.NUMBER: {
              cellContent = formatNumber(cell.value);
              break;
            }
            default: {
              cellContent = cell.value != null ? cell.value.toString() : "";
            }
          }

          if (index === 0) {
            return (
              <TableCell
                key={index}
                style={{
                  position: columnTypes.length > 1 ? "sticky" : undefined,
                  left: hasCheckBox ? 57 : 1,
                  zIndex: 1, // hide boolean toggles and rating stars when scrolling
                  padding: "13px 16px",
                  backgroundColor:
                    isFooterRow || isHovered || checked ? "inherit" : "#fff",
                }}
                onClick={this.onCellClick}
              >
                <div className={classes.TextEllipsis}>
                  <span>{cellContent}</span>
                </div>

                {scrollValue && scrollValue?.x > 0 && <GradientSeparator />}
              </TableCell>
            );
          }

          if (columnsForBorder && index < columnsForBorder) {
            // for matrix display, special display for the first cell of each row
            return (
              <TableCell
                key={index}
                className={
                  index === 0 && withCollapsedRows && withCollapsedRows > 0
                    ? classes.FirstTableCell
                    : classes.TableHeaderCell
                }
                style={style}
                onClick={this.onCellClick}
              >
                {cellContent}
              </TableCell>
            );
          } else {
            return (
              <CustomTableCell
                key={index}
                style={{ ...style, ...(isFooterRow && { color: "white" }) }}
                onCellClick={this.onCellClick}
                rowItem={rowItem}
                content={cellContent}
              />
            );
          }
        }
      )
    );
    return dataCells;
  };

  componentDidMount() {
    this.setState({
      cellProperties: this.ref.current,
    });
  }

  render() {
    const isSelected = false;
    const { redirectTo, isHovered } = this.state;
    const {
      rowItem,
      classes,
      checked,
      clickable,
      isFooterRow,
      isCollapsed,
      toggleCollapse,
      onViewItem,
      onEditItem,
      onStopExecution,
      onResumeExecution,
      onContinueExecution,
      onReplicateItem,
      onRestoreItem,
      onArchiveItem,
      onDeleteItem,
      onDetailItem,
      onClickItemRow,
      extraActions,
    } = this.props;

    const hasActions =
      onViewItem ||
      onDetailItem ||
      onStopExecution ||
      onResumeExecution ||
      onContinueExecution ||
      onArchiveItem ||
      onEditItem ||
      onRestoreItem ||
      onDeleteItem ||
      onReplicateItem ||
      !_.isEmpty(extraActions);

    // NOTE: This mechanism is redundant with the onClick prop
    // NOTE: Should also check row.isClickDisabled
    const onClickRow =
      clickable && rowItem._itemPath
        ? () => {
            if (onClickItemRow) onClickItemRow(rowItem);
            this.setState({ redirectTo: rowItem._itemPath });
          }
        : undefined;

    if (redirectTo) {
      return <Redirect to={redirectTo} push />;
    }

    return (
      <MaterialTableRow
        hover
        selected={isSelected}
        className={classNames(
          isFooterRow ? classes.FooterRow : classes.Row,
          clickable && "clickable",
          checked && "checked"
        )}
        onClick={onClickRow}
        onMouseOver={this.mouseEntering}
        onMouseOut={this.mouseLeaving}
      >
        {this.createTableDataCells()}
        {toggleCollapse ? (
          <td
            style={{
              height: "32px",
              left: "0px",
              paddingRight: "5px",
              float: "left",
              position: "absolute",
              width: "100%",
            }}
          >
            <CustomButton
              buttonContainer={{}}
              type={isCollapsed ? ACTION_TYPE.EXPAND : ACTION_TYPE.COLLAPSE}
              onClick={toggleCollapse}
            ></CustomButton>
          </td>
        ) : null}

        {hasActions ? (
          <CustomTableActionRow
            isHovered={isHovered}
            rowItem={rowItem}
            onStopExecution={
              onStopExecution ? this.handleStopExecution : undefined
            }
            onResumeExecution={
              onResumeExecution ? this.handleResumeExecution : undefined
            }
            onContinueExecution={
              onContinueExecution ? this.handleContinueExecution : undefined
            }
            onArchiveItem={onArchiveItem ? this.handleArchiveItem : undefined}
            onDeleteItem={onDeleteItem ? this.handleDeleteItem : undefined}
            onEditItem={onEditItem ? this.handleEditItem : undefined}
            onRestoreItem={onRestoreItem ? this.handleRestoreItem : undefined}
            onViewItem={onViewItem ? this.handleViewItem : undefined}
            onReplicateItem={
              onReplicateItem ? this.handleReplicateItem : undefined
            }
            onLicensedItem={
              _.includes(_.keys(rowItem), "licensed")
                ? this.handleLicensedItem
                : undefined
            }
            onExtraActions={
              extraActions && extraActions.length > 0
                ? this.handleExtraAction
                : undefined
            }
            extraActions={extraActions}
            actionBlocked={rowItem._block_actions}
            onMouseEntering={this.mouseEntering}
            onMouseLeaving={this.mouseLeaving}
          />
        ) : null}
      </MaterialTableRow>
    );
  }
}

export const GradientSeparator = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      right: "-4px",
      width: "4px",
      height: "100%",
      // NOTE: boxShadow + clipPath still produce a slight glitch, so we prefer the gradient
      // boxShadow: "0px 0px 4px 0 rgba(0, 0, 0, 0.4)",
      // clipPath: "inset(0px -4px 0px 0px)", // clip box-shadow except on right side
      background: "linear-gradient(0.25turn, rgba(0, 0, 0, 0.1), transparent)",
    }}
  />
);

const onClickStopPropagation = (e: any) => {
  if (e && e.stopPropagation) {
    e.stopPropagation();
  }
};

export const formatMultipleInputValue = (
  customFieldValue: IInputMultipleTextValue[]
) => {
  const value = _.join(
    _.map(customFieldValue, ({ value }) => String(value)),
    "\xa0\xa0\xa0"
  );
  return value;
};

export default withStyles(styles as any)(CustomTableRow);
