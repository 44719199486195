import _ from "lodash";
import { useSelector } from "react-redux";

import InputMultipleSelect from "components/Input/InputMultipleSelect";
import { allWebUsersSelector } from "containers/users/redux/selectors/webUsersSelector";
import { useTranslations } from "hooks";
import { TViewMode } from "model/application/modal/CreateEditModal";
import getFullName from "utils/formatting/getFullName";

interface IClientContactProps {
  viewMode?: TViewMode;
  main_contact?: string;
  onSelectChange: (value: any, name: string) => void;
}
const ClientContact = ({
  main_contact,
  viewMode,
  onSelectChange,
}: IClientContactProps) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;
  const webusers = useSelector(allWebUsersSelector);
  return (
    <>
      {!_.isEmpty(webusers) && (
        <InputMultipleSelect
          viewMode={viewMode}
          name="main_contact"
          multipleSelection={false}
          onChange={onSelectChange}
          options={_.map(webusers, (user) => {
            return {
              key: user.id,
              label: getFullName(user),
            };
          })}
          defaultValue={webusers
            .filter((u) => u.id === main_contact)
            .map((u) => ({
              key: u.id,
              label: getFullName(u),
            }))}
          lang={lang}
          langlabel={label.inputClientContactManagement}
          useDropDownMenu
        />
      )}
    </>
  );
};

export default ClientContact;
