import { Box, Button } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  BasicCloseIcon,
  BasicDialogActions,
  BasicDialogContent,
  BasicDialogTitle,
} from "components/Dialog/BasicDialog";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputText from "components/Input/InputText/InputText";
import SnackbarMessage from "components/Snackbars/SnackbarMessage";
import { ITerritory } from "containers/territories/model";
import {
  getIsUpdatingTerritories,
  getTerritoriesSchema,
} from "containers/territories/redux/selectors";
import useFormState from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { IOption } from "model/application/components";
import { IListSchema } from "model/entities/List";
import { formatFormFieldsForBackend } from "utils/formatting/customFieldValues";

import InputEditShape, { SHAPE_EDIT_MODE } from "../InputShape/InputEditShape";
import TerritoryForm from "../TerritoryForm";
import { getDefaultOwners } from "../utils/getDefaultOwners";

export interface IEditTerritoryPanel {
  editMode: SHAPE_EDIT_MODE;
  territory: ITerritory; // initial value for the territory
  ownerOptions: IOption<string>[];
  onSave?: (args: ITerritory) => void;
  onClose: () => void;
  onCancel: () => void;
  onEditShape?: () => void;
  onResetShape: () => void;
}

const EditTerritoryPanel = ({
  editMode,
  territory: initTerritory,
  ownerOptions,
  onSave,
  onClose,
  onCancel,
  onEditShape,
  onResetShape,
}: IEditTerritoryPanel) => {
  const lang = useTranslations();
  const langKey =
    lang.containers.lists.subCategories.territories.editTerritoryModal;

  const territorySchema = useSelector(getTerritoriesSchema);
  const isUpdatingTerritory = useSelector(getIsUpdatingTerritories);
  const formState = useFormState({
    initAttributes: initTerritory,
  });

  const {
    attributes: territory,
    handleInputChange,
    handleMultipleSelectChange,
  } = formState;

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <BasicDialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {formatString(langKey.editTitle, [initTerritory.name])}
          <BasicCloseIcon onClick={onClose} />
        </Box>
      </BasicDialogTitle>

      <BasicDialogContent>
        <InputText
          name={"name"}
          label={langKey.inputName.title}
          defaultValue={territory.name}
          onChange={handleInputChange}
          required
        />

        <TerritoryForm formState={formState} />

        <div data-testid="input-edit-shape">
          <InputEditShape
            editMode={editMode}
            onEditShape={onEditShape}
            onResetShape={onResetShape}
            required
          />
        </div>

        <InputMultipleSelect
          useDropDownMenu
          name="owners"
          onChange={handleMultipleSelectChange}
          options={ownerOptions}
          defaultValue={getDefaultOwners({
            owners: territory.owners,
            options: ownerOptions,
          })}
          langlabel={langKey.inputOwners}
          lang={lang}
        />
      </BasicDialogContent>

      <BasicDialogActions>
        <Box justifyContent="flex-start" flexGrow={1}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={onCancel}
            style={{ marginRight: "16px" }}
          >
            {lang.modal.cancel}
          </Button>

          <Button
            color="secondary"
            variant="contained"
            disableElevation
            onClick={
              onSave &&
              (() => onSave(formatTerritoryForEdit(territory, territorySchema)))
            }
            disabled={!onSave || !territory.name}
          >
            {lang.modal.save}
          </Button>
        </Box>
        <SnackbarMessage
          isFetchActionOngoing={isUpdatingTerritory}
          lang={lang}
        />
      </BasicDialogActions>
    </div>
  );
};

const formatTerritoryForEdit = (
  territory: ITerritory,
  territorySchema: IListSchema[]
) => {
  const formattedTerritory = formatFormFieldsForBackend({
    formFields: territory,
    fieldsSchema: territorySchema,
  });

  return _.pick(
    formattedTerritory,
    "id",
    "name",
    "shape",
    "owners",
    ..._.map(territorySchema, "column_tag")
  ) as ITerritory;
};

export default EditTerritoryPanel;
