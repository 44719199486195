import { Component } from "react";

import { TValidateFunction } from "components/Forms/CreateEditViewForm";
import InputText from "components/Input/InputText/InputText";
import TLang from "model/application/Lang";

export interface IWebRole {
  key: string;
  label: string;
}

interface IInputWebRoleProps {
  onChange: (e: IWebRole) => void;
  element: IWebRole;
  editMode?: boolean;
  lang: TLang;
}

export const getErrorMessageForWebRole: TValidateFunction<IWebRole> = ({
  attributes,
  lang,
}) => {
  const { key, label } = attributes;
  const result: any = {};
  if (!key || key.length === 0)
    result["key"] = lang.components.inputErrors.empty;
  if (!label || label.length === 0)
    result["label"] = lang.components.inputErrors.empty;
  return result;
};

export class WebUserRoleForm extends Component<IInputWebRoleProps, IWebRole> {
  constructor(props: IInputWebRoleProps) {
    super(props);
    const { element } = props;
    this.state = element;
  }

  handleInputChange = (value: any, name: string) => {
    const newState = {
      ...this.state,
      [name]: value,
    };
    this.setState(newState);
    this.props.onChange(newState);
  };

  render() {
    const { element, lang, editMode } = this.props;

    const labelToDisplay = lang.modal.createEditOption;

    return (
      <div>
        <InputText
          name="key"
          lang={labelToDisplay.inputKey}
          defaultValue={element.key}
          onChange={this.handleInputChange}
          disabled={editMode}
          required={true}
        />
        <InputText
          name="label"
          lang={labelToDisplay.inputLabel}
          defaultValue={element.label}
          onChange={this.handleInputChange}
          required={true}
        />
      </div>
    );
  }
}

export default WebUserRoleForm;
