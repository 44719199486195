import { Theme } from "@material-ui/core";

import { Black, GreyDark } from "assets/colors";
export default (theme: Theme) => ({
  RadioButtonsCustom: {
    marginBottom: "-10px",
  },
  RadioCustom: {
    // color: theme.palette.primary.main,
    color: Black,
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "10%",
  },
  DropdownItem: {
    display: "block",
    width: "100%",
    padding: "16px 16px 8px",
    clear: "both",
    fontWeight: "400",
    color: "#212529",
    textAlign: "inherit",
    whiteSpace: "nowrap",
    border: "0",
  },
  FilterButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
  },
  FilterButton: {
    position: "sticky",
    bottom: "0",
    height: "36px",
    "box-shadow": "none",
    border: "0px solid !important",
    "&:hover": {
      background: theme.palette.secondary.main,
      opacity: ".5",
    },
    borderRadius: "4px",
    background: theme.palette.secondary.main,
  },
  ClearSelectionButton: {
    fontSize: "16px",
    color: GreyDark,
    marginBottom: "10px",
  },
  NoMatchLabel: {
    fontSize: "16px",
    color: GreyDark,
    marginBottom: "10px",
  },
  FilterButtonLabel: {
    height: "20px",
    fontWeight: "500",
    lineHeight: "1.25",
    letterSpacing: "-0.25",
    color: "#fefefe",
  },
  //react tree: https://github.com/jakezatecky/react-checkbox-tree/issues/30
  DisplayNone: {
    display: "none!important",
    padding: "0px",
  },
  IconColor: {
    color: theme.palette.primary.main,
  },
  IconColorUncheck: {
    border: "2px solid #6c6c6c",
    color: "#fefefe",
  },
});
