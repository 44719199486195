import {
  CUSTOM_FIELD_TYPE,
  META_EXPRESSION_SCOPE,
  parseRawMetaExpression,
} from "fieldpro-tools";
import _ from "lodash";

import { IQuestionnaire } from "model/entities/Workflow";
import { getMetaExpressionString } from "utils/metaExpressions";

export const getTagArrays = (formattedMetaExpression: string) => {
  const tableau = _.split(formattedMetaExpression, "$");
  for (let i = 0; i < tableau.length; i++) {
    if (tableau[i].trim() === "") {
      tableau.splice(i, 1);
    }
  }
  return tableau;
};

// TODO: merge this with other validation functions
export const addRequiredCheck = (
  formattedMetaExpression: string,
  error: string | undefined,
  required = false
) => {
  let error2 = "";
  if (error && error.length > 0) {
    error2 = error;
  } else {
    if (getTagArrays(formattedMetaExpression).length > 0) {
      error2 = "";
    } else {
      // TODO: properly translate
      error2 = required ? "This property cannot be empty" : "";
    }
  }

  return error2;
};

interface IGetStringWithoutSpaces {
  str?: string;
  replacement?: string;
}

export const getStringWithoutSpaces = ({
  str,
  replacement = "_",
}: IGetStringWithoutSpaces) => {
  if (!str) {
    return "";
  }
  return str.split(" ").join(replacement);
};

interface IGetOperatorIsRequired {
  firstOperand?: string;
  matrixTags?: string[];
}

export const getOperatorIsRequired = ({
  firstOperand,
  matrixTags,
}: IGetOperatorIsRequired) => {
  const metaExpressionString = getMetaExpressionString(firstOperand);
  if (isBasedOnMatrixField({ metaExpressionString, matrixTags })) {
    return false;
  }
  return true;
};

interface IIsBasedOnMatrixField {
  metaExpressionString?: string;
  matrixTags?: string[];
}

export const isBasedOnMatrixField = ({
  metaExpressionString,
  matrixTags,
}: IIsBasedOnMatrixField) => {
  if (!metaExpressionString) {
    return false;
  }
  const metaExpression = parseRawMetaExpression(metaExpressionString);
  if (
    metaExpression?.scope === META_EXPRESSION_SCOPE.ACTIVITY_REPORT &&
    _.includes(matrixTags, metaExpression.custom_field1)
  ) {
    return !!metaExpression.custom_field2;
  }
};

export const getMatrixTags = (questionnaire?: IQuestionnaire) => {
  const matrixQuestions = _.filter(questionnaire?.questions, {
    type: CUSTOM_FIELD_TYPE.MATRIX_ON_LIST,
  });
  return _.map(matrixQuestions, "tag");
};

export const getExtensionFromFileName = (fileName: string): string => {
  // eslint-disable-next-line no-useless-escape
  const patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  const fileExtension = fileName.match(patternFileExtension);
  if (fileExtension && fileExtension?.length > 0) {
    const res = fileExtension[0].split(".").pop() || fileExtension[0];
    return res.toUpperCase();
  } else {
    return "-";
  }
};
