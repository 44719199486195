import _ from "lodash";

import ICustomer from "model/entities/Customer";
import {
  ICustomerDetailForGPSTracking,
  IGpsRecord,
  IKPI,
  ITimelineActionsForGPSTracking,
  TIMELINE_EVENT_TYPE,
} from "model/entities/Dashboard";
import { IList } from "model/entities/List";
import { IActivity, REPORT_PREREQUISITE } from "model/entities/Workflow";

export interface ICoordWithTimestamp {
  timestamp: string;
  lat: number;
  lng: number;
}

export interface ICoord {
  lat: number;
  lng: number;
}

export interface IProcessedCoordData {
  [userId: string]: {
    [timestamp: string]: {
      lat: number;
      lng: number;
    };
  };
}

export const getDistanceBetweenCoords = (coord1: ICoord, coord2: ICoord) => {
  if (coord1.lat == coord2.lat && coord1.lng == coord2.lng) {
    return 0;
  }

  const radlat1 = (Math.PI * coord1.lat) / 180;
  const radlat2 = (Math.PI * coord2.lat) / 180;

  const theta = coord1.lng - coord2.lng;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344; //convert miles to km

  return Math.round(dist * 100) / 100;
};

export const simplifyDataWithTime = (
  dataAsArray: ICoordWithTimestamp[],
  limit: number
) => {
  // simplify with Time
  const result = {};
  let refTimeStamp: number;
  dataAsArray.forEach((v, k) => {
    if (k == 0 || new Date(v.timestamp).getTime() - refTimeStamp > limit) {
      result[v.timestamp] = v;
      refTimeStamp = new Date(v.timestamp).getTime();
    }
  });
  return result;
};

export const simplifyDataWithDistance = (
  dataAsArray: ICoordWithTimestamp[],
  limit: number
) => {
  const result = {};
  let refLatLng: ICoord;
  dataAsArray.forEach((v, k) => {
    const coord: ICoord = { lat: v.lat, lng: v.lng };
    if (k == 0 || getDistanceBetweenCoords(coord, refLatLng) > limit) {
      result[v.timestamp] = coord;
      refLatLng = coord;
    }
  });
  return result;
};

export const sortFormattedDataWithCustomersForUser = (
  datas: any,
  simplifyData?: boolean
): { [timestamp: string]: ICoord } => {
  const dataAsArray: ICoordWithTimestamp[] = [];
  Object.keys(datas).forEach((timestamp: string) => {
    dataAsArray.push({
      timestamp,
      ...datas[timestamp],
    });
  });
  dataAsArray.sort(
    (a: any, b: any) =>
      new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  if (simplifyData) {
    // simplify with Time
    // const FIVE_MIN = 5 * 60 * 1000;
    // return simplifyDataWithTime(dataAsArray, FIVE_MIN);

    // simplify with Location
    const ONE_KILOMETER = 1;
    return simplifyDataWithDistance(dataAsArray, ONE_KILOMETER);
  } else {
    const result: any = {};
    dataAsArray.forEach(
      (t: { lat: number; lng: number; timestamp: string }) => {
        const { timestamp: _timestamp, ...rest } = t;
        result[t.timestamp] = rest;
      }
    );
    return result;
  }
};

/**
 * This function gets the data from the backend, and format them to be centered on the users:
 * {
 *   "userId1": {
 *     "timestamp1": {
 *        lat,lng
 *     },
 *     "timestamp2": {
 *        lat,lng
 *     },
 *     ...
 *   },
 *   ...
 * }
 */
export const formatData = (data: IGpsRecord[], userIds: string[]) => {
  if (!data) return {};
  const users: any = {};
  // go through the list a first time to get the list of users
  for (const d of data) {
    if (!userIds.includes(d.user_id)) continue;
    const coordinates = { lat: d.lat, lng: d.lng };
    if (!users.hasOwnProperty(d.user_id)) {
      users[d.user_id] = {
        [`${d.timestamp}`]: coordinates,
      };
    } else {
      users[d.user_id][d.timestamp] = coordinates;
    }
  }
  return users;
};

export const prepareLocationData = (
  chart: IKPI,
  userFilter: { key: string }[],
  timelineActions: ITimelineActionsForGPSTracking[],
  customerDetails: ICustomerDetailForGPSTracking[],
  selectedUser?: string,
  simplifyData?: boolean
): IProcessedCoordData => {
  let result: IProcessedCoordData = {};
  if (
    chart.data &&
    Array.isArray(chart.data) &&
    chart.data[0].hasOwnProperty("label")
  ) {
    const gpsRecordData = chart.data.find(
      (c: any) => c.label === "gps_records"
    );
    if (gpsRecordData) {
      const gpsRecords: IGpsRecord[] = (
        gpsRecordData.data as IGpsRecord[]
      ).filter((c) => !c.acc || c.acc <= 100);
      result = formatData(
        gpsRecords,
        userFilter.map((u) => u.key)
      );
    }

    // sort FormattedData by timestamp
    if (selectedUser) {
      result[selectedUser] = sortFormattedDataWithCustomersForUser(
        result[selectedUser] || {},
        simplifyData
      );
    }

    timelineActions = timelineActions.filter((a) => a.user_id === selectedUser);

    if (simplifyData) {
      timelineActions = timelineActions.filter(
        (a) => a.action_code == TIMELINE_EVENT_TYPE.CHECK_IN // i.e. disregard check out and create activity report, amongst others
      );
    }

    timelineActions.forEach((a) => {
      const customer = customerDetails.find(
        (p) => p.customer_id === a.customer_id
      );
      if (customer && selectedUser && customer.lat && customer.lng) {
        result[selectedUser][a.timestamp] = {
          lat: customer.lat,
          lng: customer.lng,
        };
      }
    });
  }
  return result;
};

// TODO: refacto this function to accept a list of customers of return the formatted list of customers (without touching the list object)
export const prepareCustomerList = (
  customerList: IList<ICustomer>,
  customersVisited: ICustomerDetailForGPSTracking[],
  timelineActions: ITimelineActionsForGPSTracking[],
  selectedUser: string | undefined
): IList<ICustomer> => {
  if (!selectedUser) {
    return {
      ...customerList,
      items: [],
    };
  }

  const mapCustomersAlreadyUsed = {};

  const partialCustomers = timelineActions
    .filter((a) => a.user_id === selectedUser)
    .sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    )
    .filter((a) => {
      if (mapCustomersAlreadyUsed[a.customer_id]) {
        return false;
      }
      mapCustomersAlreadyUsed[a.customer_id] = true;
      return true;
    })
    .map((a) => {
      const customer = _.find(customersVisited, { customer_id: a.customer_id });
      if (
        !(
          customer &&
          customer.lat &&
          customer.lng &&
          customer.active &&
          customer.name
        )
      ) {
        return undefined;
      }

      // TODO: this will now be undefined in many cases, but it does not seem to have an impact
      // So we should remove this part; and if some customer fields are missing, we need to pass them in the dashboard query (see ICustomerDetailForGPSTracking)
      const customerItem = _.find(
        customerList.items,
        (item) => item._id === customer.customer_id
      );

      return {
        ...customerItem,
        _active: customer.active,
        _id: customer.customer_id,
        _location: { lng: customer.lng, lat: customer.lat, acc: 10 },
        _name: customer.name,
      } as ICustomer;
    });

  const result: IList<ICustomer> = {
    ...customerList,
    items: _.compact(partialCustomers),
  };

  return result;
};
interface IHideCustomersWithNoGeoCheckIn {
  customers: ICustomer[];
  activities: IActivity[];
  userTimelineActions: ITimelineActionsForGPSTracking[];
}

export function removeCustomersThatDidntCheckIn({
  activities,
  customers,
  userTimelineActions,
}: IHideCustomersWithNoGeoCheckIn) {
  return _(customers)
    .map((customer) => {
      // we get all the customers checkin actions
      const timeLineActionsRelatedToCustomer = _(userTimelineActions)
        .filter({
          customer_id: customer._id,
        })
        .filter(({ action_code }) =>
          _.includes(
            [
              TIMELINE_EVENT_TYPE.CREATE_ACTIVITY_REPORT,
              TIMELINE_EVENT_TYPE.CREATE_SUBMISSION, // LEGACY ELSEWHERE ? (BUT STILL IN USE HERE)
            ],
            action_code
          )
        )
        .value();

      //we filter with the ones linked to an activity that requires geo checkin
      const checkInsOnActivitiesThatRequireVisits = _.filter(
        timeLineActionsRelatedToCustomer,
        (act) => {
          const activity = _.find(activities, {
            id: _.get(act, "activity_id"),
          });
          const needVisit =
            activity &&
            [REPORT_PREREQUISITE.NEED_VISIT_AND_GEO_CHECKIN].includes(
              activity.report_prerequisite
            );

          return needVisit;
        }
      );

      //if the customer filled any return it
      if (_.size(checkInsOnActivitiesThatRequireVisits) != 0) {
        return customer;
      }
    })
    .compact()
    .value();
}
