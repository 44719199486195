import React from "react";

import { isOptimetriksRole } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputSelect from "components/Input/InputSelect";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import { getPrivilege } from "containers/authentication/redux/selector";
import { dashboardsComposedSelector } from "containers/dashboards/redux/selectors";
import { MAIN_DASHBOARD_LIMIT } from "containers/home/MainDashboards";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { DEFAULT_DATE_FILTER, IClient } from "model/entities/Client";
import { IDashboard } from "model/entities/Dashboard";
import { getOptionsInputSelect } from "utils/getOptionsInputSelect";

export interface IDashboardsTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  onInputChange: any;
  onSelectChange: any;
  shouldDisplayNewPassword?: boolean;
  is_bigquery_enabled?: boolean;
  main_dashboards?: any[];
  msql_connection?: any;
}

const splitAndSortClientDashboards = (dashboards: IDashboard[]) => {
  //Split default/client dashboards + order the client one by title

  const homeDashboards = _.filter(dashboards, (dashboard: IDashboard) =>
    _.startsWith(dashboard.id, "_home_dashboard_")
  );
  const otherDashboards = _.sortBy(
    _.filter(
      dashboards,
      (dashboard: IDashboard) =>
        !_.includes(_.map(homeDashboards, "id"), dashboard.id)
    ),
    (dashboard: IDashboard) => dashboard.title
  );

  return _.concat(homeDashboards, otherDashboards);
};

export const DashboardsTab: React.FunctionComponent<IDashboardsTabProps> = ({
  viewMode = "CREATE",
  default_date_filter,
  is_bigquery_enabled,
  client_email,
  dataset_name,
  main_dashboards,
  private_key,
  msql_connection,
  shouldDisplayNewPassword,
  onInputChange,
  onSelectChange,
}) => {
  const lang = useTranslations();
  const role = useSelector(getPrivilege);
  const dashboards = useSelector(dashboardsComposedSelector);
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  const [mssqlConnection, setMssqlConnection] = React.useState<{
    host?: string;
    port?: string;
    db_name?: string;
    user?: string;
    password?: string;
    is_enabled?: boolean;
  }>(msql_connection ?? {});

  const allDashboards = splitAndSortClientDashboards(dashboards);

  const handleMultipleSelect = (value: any, name: string) => {
    const selectedDashboards = _.map(value, ({ key, label }: any) => ({
      id: key,
      title: label,
    }));
    onSelectChange(selectedDashboards, name);
  };

  const onMssqlAttrVAlueChange = (value: any, name: string) => {
    mssqlConnection[name] = value;
    setMssqlConnection(mssqlConnection);
    onInputChange(mssqlConnection, "msql_connection");
  };
  return (
    <>
      <FormFieldBackground viewMode={viewMode}>
        <InputSelect
          viewMode={viewMode}
          lang={label.inputDashboardTabDefaultDateFilter}
          name={"default_date_filter"}
          value={default_date_filter as string}
          options={getOptionsInputSelect({
            listKeys: Object.keys(DEFAULT_DATE_FILTER),
            lang: label.inputDashboardTabDefaultDateFilter.options,
          })}
          onChange={onSelectChange}
        />
      </FormFieldBackground>

      {isOptimetriksRole(role) && (
        <>
          <InputText
            lang={label.inputDashboardTabOldLockPassword}
            onChange={onInputChange}
            type={INPUT_TEXT_TYPE.PASSWORD}
            showPasswordButton={true}
            aria-label="OldSensibleDataPassword"
            name="old_sensible_data_password"
            autoComplete={"off"}
            viewMode={viewMode}
          />

          {shouldDisplayNewPassword ? (
            <FormFieldBackground viewMode={viewMode}>
              <InputText
                lang={label.inputDashboardTabNewLockPassword}
                key={"new_sensible_data_password"}
                onChange={onInputChange}
                type={INPUT_TEXT_TYPE.PASSWORD}
                showPasswordButton={true}
                aria-label="NewSensibleDataPassword"
                name="new_sensible_data_password"
                viewMode={viewMode}
              />
            </FormFieldBackground>
          ) : null}

          <FormFieldBackground viewMode={viewMode}>
            <InputBoolean
              lang={label.inputDashboardTabEnableBigQuery}
              key={"is_bigquery_enabled"}
              onChange={onInputChange}
              name="is_bigquery_enabled"
              viewMode={viewMode}
              defaultValue={is_bigquery_enabled}
              dataTestId="bigquery-toggle"
            />
            {is_bigquery_enabled && (
              <>
                <InputText
                  lang={label.inputDashboardTabBigQueryDataSetName}
                  defaultValue={dataset_name}
                  key={"dataset_name"}
                  onChange={onInputChange}
                  aria-label="dataset_name"
                  name="dataset_name"
                  viewMode={viewMode}
                />
                <InputText
                  defaultValue={client_email}
                  lang={label.inputDashboardTabBigQueryClientEmail}
                  key={"client_email"}
                  onChange={onInputChange}
                  aria-label="client_email"
                  name="client_email"
                  viewMode={viewMode}
                />
                <InputText
                  lang={label.inputDashboardTabBigQueryPrivateKey}
                  key={"private_key"}
                  multiline={true}
                  defaultValue={private_key}
                  onChange={onInputChange}
                  type={INPUT_TEXT_TYPE.PASSWORD}
                  showPasswordButton={true}
                  aria-label="private_key"
                  name="private_key"
                  viewMode={viewMode}
                />
              </>
            )}
          </FormFieldBackground>
          <FormFieldBackground viewMode={viewMode}>
            <InputBoolean
              lang={label.inputDashboardTabEnableMsql}
              key={"is_msql_enabled"}
              onChange={onMssqlAttrVAlueChange}
              name="is_enabled"
              viewMode={viewMode}
              defaultValue={mssqlConnection?.is_enabled}
              dataTestId="msql-toggle"
            />
            {mssqlConnection?.is_enabled && (
              <>
                <InputText
                  lang={label.inputDashboardTabMsqlHost}
                  defaultValue={mssqlConnection?.host}
                  key={"msql_host"}
                  onChange={onMssqlAttrVAlueChange}
                  aria-label="msql_host"
                  name="host"
                  viewMode={viewMode}
                />
                <InputText
                  lang={label.inputDashboardTabMsqlPort}
                  key={"msql_port"}
                  defaultValue={mssqlConnection?.port}
                  onChange={onMssqlAttrVAlueChange}
                  aria-label="msql_port"
                  name="port"
                  viewMode={viewMode}
                />
                <InputText
                  lang={label.inputDashboardTabMsqlDbname}
                  key={"msql_db_name"}
                  defaultValue={mssqlConnection?.db_name}
                  onChange={onMssqlAttrVAlueChange}
                  type={INPUT_TEXT_TYPE.PASSWORD}
                  showPasswordButton={true}
                  aria-label="msql_db_name"
                  name="db_name"
                  viewMode={viewMode}
                />
                <InputText
                  lang={label.inputDashboardTabMsqlUser}
                  key={"msql_user"}
                  defaultValue={mssqlConnection?.user}
                  onChange={onMssqlAttrVAlueChange}
                  type={INPUT_TEXT_TYPE.PASSWORD}
                  showPasswordButton={true}
                  aria-label="msql_user"
                  name="user"
                  viewMode={viewMode}
                />
                <InputText
                  defaultValue={mssqlConnection?.password}
                  lang={label.inputDashboardTabMsqlPassword}
                  type={INPUT_TEXT_TYPE.PASSWORD}
                  key={"msql_password"}
                  onChange={onMssqlAttrVAlueChange}
                  aria-label="msql_password"
                  name="password"
                  showPasswordButton={true}
                  viewMode={viewMode}
                />
              </>
            )}
          </FormFieldBackground>
        </>
      )}

      {!_.isEmpty(allDashboards) && (
        <InputMultipleSelect
          name="main_dashboards"
          defaultValue={_.compact(
            _.map(main_dashboards, (dashboard) => {
              const dashboardFound = _.find(allDashboards, {
                id: dashboard.id,
              });
              if (!dashboardFound) {
                return null;
              }
              return {
                key: dashboard.id,
                label: dashboardFound?.title,
              };
            })
          )}
          onChange={handleMultipleSelect}
          options={_.map(
            _.filter(allDashboards, (dash: IDashboard) => dash.active), //Show only active dashboards
            (dashboard: IDashboard) => {
              return {
                key: dashboard.id,
                label: dashboard.title ?? dashboard.name,
                value: dashboard.id,
              };
            }
          )}
          viewMode={viewMode}
          lang={lang}
          langlabel={label.inputDashboardTabDefaultDashboards}
          limit={MAIN_DASHBOARD_LIMIT}
        />
      )}
    </>
  );
};

export default DashboardsTab;
