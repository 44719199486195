import { useState } from "react";

import { Box, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as turf from "@turf/turf";
import dateformat from "dateformat";
import { Feature } from "geojson";
import _ from "lodash";
import {
  Layer,
  LngLatBoundsLike,
  Map,
  Marker,
  NavigationControl,
  PaddingOptions,
  PointLike,
  Popup,
  Source,
  ViewState,
} from "react-map-gl";
import { useSelector } from "react-redux";

import { GreyDark } from "assets/colors";
import { getMapRegion } from "components/Map/getMapRegion";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { CustomOverlay } from "containers/customers/subcategories/map/CustomersMap/controls";
import ICustomer from "model/entities/Customer";
import { IList } from "model/entities/List";
import IUser from "model/entities/User";

import GeoTrackingMarker from "./GeoTrackingMarker";
import { matchingRoutesLayer } from "./layers";
import RouteLinesLayer from "./layers/RouteLinesLayer";
import UserPositionsLayer from "./layers/UserPositionsLayer";
import { getUserColors } from "./utils/getUserColors";

export const GEO_TRACKING_CHART = {
  WIDTH: "calc(100vw - 100px)",
  HEIGHT: "calc(100vh - 240px)",
  STYLE: {
    DEFAULT: "ck3el600w480q1cnsgbgq9i14",
    SATELLITE: "clrrxzqh900gp01o3atrgg8s1",
  },
  Z_INDEXES: {
    MARKER: 10,
    CONTROLS: 15,
    PANEL: 20,
    POPUP: 21,
  },
};

const styles = {
  GeoTrackingChart: {
    "& .mapboxgl-ctrl-top-left": {
      zIndex: GEO_TRACKING_CHART.Z_INDEXES.CONTROLS,
    },
  },
  MarkerIcon: {
    width: "35px",
    height: "35px",
    "margin-left": "10px",
    "margin-top": "-10px",
  },
  MarkerNoEvents: {
    pointerEvents: "none",
  },
  ButtonShowMatching: {
    position: "absolute",
    left: "50px",
    top: "10px",
    textAlign: "center",
    zIndex: GEO_TRACKING_CHART.Z_INDEXES.CONTROLS,
  },
  MapTooltip: {
    fontFamily: "BasierCircle",
    fontSize: "14px",
    "& .mapboxgl-popup-content": {
      padding: "8px 16px",
    },
  },
} as const;

const useStyles = makeStyles(styles);

export interface IGeoTrackingChartData {
  [userId: string]: IUserPositionsByDate;
}

type TInitialViewState =
  | (Partial<ViewState> & {
      bounds?: LngLatBoundsLike | undefined;
      fitBoundsOptions?:
        | {
            offset?: PointLike | undefined;
            minZoom?: number | undefined;
            maxZoom?: number | undefined;
            padding?: number | PaddingOptions | undefined;
          }
        | undefined;
    })
  | undefined;

interface IUserPositionsByDate {
  [date: string]: { lng: number; lat: number };
}

interface IGeoTrackingChartProps {
  data: IGeoTrackingChartData;
  matchingRoutes: any;
  users: IUser[];
  customerList: IList<ICustomer>;
  onChangeSelectedUser: (userId?: string) => void;
  onChangeSelectedCustomer: (customerId: string) => void;
  selectedUser?: string;
}

export interface IPopupState {
  uid: string;
  time: string;
}

export const GeoTrackingChart = ({
  data,
  users,
  customerList,
  matchingRoutes,
  onChangeSelectedUser,
  onChangeSelectedCustomer,
  selectedUser,
}: IGeoTrackingChartProps) => {
  const client = useSelector(getSelectedClient);
  const classes = useStyles();
  const [showMatchingRoutes, setShowMatchingRoutes] = useState(false);
  const [popupState, setPopupState] = useState<IPopupState | undefined>();
  // computed only once on mount
  const [userColors] = useState(() => getUserColors(data, users));
  const [initialBounds] = useState(() => getInitialBounds(data, selectedUser));

  let initialViewState: TInitialViewState = {
    // combination of long, lat, zoom to view full map
    longitude: 0,
    latitude: 36,
    zoom: 2,
    pitch: 0,
    bearing: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
  };
  if (!_.isEmpty(initialBounds)) {
    initialViewState = {
      bounds: initialBounds as LngLatBoundsLike,
      fitBoundsOptions: { padding: 50 },
    };
  }

  return (
    <Box className={classes.GeoTrackingChart}>
      <Map
        initialViewState={initialViewState}
        // viewState={viewState} // WARNING: do not pass initialViewState AND viewState. It is better for performance to leave component uncontrolled ?
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_API}
        mapStyle={`mapbox://styles/smala/${GEO_TRACKING_CHART.STYLE.DEFAULT}`}
        style={{
          width: GEO_TRACKING_CHART.WIDTH,
          height: GEO_TRACKING_CHART.HEIGHT,
        }}
        worldview={getMapRegion(client)}
      >
        <NavigationControl position="top-left" />

        <UserPositionsLayer
          data={data}
          users={users}
          userColors={userColors}
          selectedUser={selectedUser}
          setPopupState={setPopupState}
          onChangeSelectedUser={onChangeSelectedUser}
        />

        {/*
         * NOTE 1: Closely related to UserPositionsLayer
         * NOTE 2: The items here are NOT those we fetch on app mount;
         * they come from the dashboard query and are added with prepareCustomerList.
         *
         * A good refacto would be to keep original customerList (or just schema) and pass the array of formatted customers on the side
         */}
        {_.map(customerList.items, (customer, index) => {
          const coordinates = customer._location;
          return (
            <Marker
              key={`marker-${customer._id}`}
              draggable={false}
              latitude={coordinates.lat}
              longitude={coordinates.lng}
              anchor="bottom"
              style={{ zIndex: GEO_TRACKING_CHART.Z_INDEXES.MARKER }}
            >
              <GeoTrackingMarker
                position={index + 1}
                // customer={customer}
                onClick={() => onChangeSelectedCustomer(customer._id)}
              />
            </Marker>
          );
        })}

        {popupState && (
          <Popup
            className={classes.MapTooltip}
            style={{ zIndex: GEO_TRACKING_CHART.Z_INDEXES.POPUP }}
            longitude={data[popupState.uid][popupState.time].lng}
            latitude={data[popupState.uid][popupState.time].lat}
            closeButton={false}
            offset={[0, -4] as [number, number]}
            anchor="bottom"
          >
            <div style={{ fontWeight: 500 }}>
              {dateformat(popupState.time, "ddd mmmm dS yyyy")}
            </div>
            <div style={{ color: GreyDark }}>
              {dateformat(popupState.time, "h:MM:ss TT Z")}
            </div>
          </Popup>
        )}

        <CustomOverlay>
          <RouteLinesLayer
            data={data}
            userColors={userColors}
            selectedUser={selectedUser}
            showMatchingRoutes={showMatchingRoutes}
          />
        </CustomOverlay>

        {showMatchingRoutes && !_.isEmpty(matchingRoutes) && (
          <Source
            id={"source-matching-routes"}
            type="geojson"
            data={{
              type: "Feature",
              properties: {},
              geometry: {
                coordinates: matchingRoutes,
                type: "LineString",
              },
            }}
          >
            <Layer {...matchingRoutesLayer} />
          </Source>
        )}
      </Map>

      {selectedUser && !_.isEmpty(matchingRoutes) && (
        <div className={classes.ButtonShowMatching}>
          <Button
            variant="contained"
            color={showMatchingRoutes ? "secondary" : "primary"}
            onClick={() =>
              setShowMatchingRoutes((showMatchingRoutes) => !showMatchingRoutes)
            }
          >
            {showMatchingRoutes
              ? "Hide matching routes"
              : "Show matching routes"}
          </Button>
        </div>
      )}
    </Box>
  );
};

const getInitialBounds = (
  data: IGeoTrackingChartData,
  selectedUser?: string
) => {
  const positionsToFit = selectedUser ? [data[selectedUser]] : _.values(data);
  const pointCoords = getFlatPositionCoords(positionsToFit);

  if (_.isEmpty(pointCoords)) {
    return [];
  }

  const userCoordsFeature: Feature = {
    type: "Feature",
    geometry: {
      type: "MultiPoint",
      coordinates: pointCoords,
    },
    properties: {},
  };

  return turf.bbox(userCoordsFeature) as LngLatBoundsLike;
};

const getFlatPositionCoords = (
  arrayOfUserPositionsByDate: IUserPositionsByDate[]
) => {
  return _(arrayOfUserPositionsByDate)
    .map((userPositionsByDate) => {
      return _.map(_.values(userPositionsByDate), (position) => {
        if (!position?.lng || !position?.lat) {
          return null;
        }
        return [position.lng, position.lat];
      });
    })
    .flatten()
    .compact()
    .value();
};

export default GeoTrackingChart;
