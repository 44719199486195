import { Component, ReactNode } from "react";

import {
  Box,
  LabelDisplayedRowsArgs,
  Paper,
  TablePagination,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import classNames from "classnames";
import _ from "lodash";
import fastCompare from "react-fast-compare";
import { BrowserRouter } from "react-router-dom";

import { primaryColor } from "assets/colors";
import MenuButton from "components/Buttons/MenuButton";
import CustomFullScreenDialog from "components/Dialog/CustomFullScreenDialog";
import PictureDialog from "components/Dialog/PictureDialog";
import EmptyTable from "components/EmptyTable/EmptyTable";
import { FILTER_TAG } from "components/Filter/TypeFilter";
import { DownloadIcon } from "components/Input/InputFile/InputFile";
import MapWithMarker from "components/Map/MapWithMarker";
import { TableSkeletonLoader } from "components/Progress/TableSkeletonLoader";
import {
  TABLE_COLUMN_TYPE,
  TColumnType,
  TRowAction,
} from "components/Table/model";
import { getLangObject } from "lang/utils";
import { ITableAction } from "model/application/Table";

import styles from "../styles";
import TablePaginationDescription from "../TablePaginationDescription";
import { getScrollPosition } from "../utils";
import CustomTableActionsHeader from "./CustomTableActionsHeader";
import CustomTableBody from "./CustomTableBody";
import CustomTableHeader from "./CustomTableHeader";
import { getRowId } from "./CustomTableRow.utils";
import {
  getDefaultOrder,
  getDefaultOrderBy,
  getOrderByColumnType,
  getSortedRows,
} from "./utils/getSortingFunction";
import handleExactSearch from "./utils/handleExactSearch";
import { labelTableRowsRange } from "./utils/labelRowsFunction";

export interface ILoadPage {
  pageNumber: number; //represent the current page. Start by 1 in general
  pageSize: number; //represent the total of elements presented
  clearExistingTableItems?: boolean; //if true, clear the existing table items
  searchTerm?: string; //if defined, the search term to use
}

export type TCustomTableProps = {
  classes: any;
  rowData: any[];
  columnTypes: TColumnType[];
  onEditItem?: TRowAction;
  onArchiveItem?: TRowAction;
  onDeleteItem?: TRowAction;
  onLicenseItem?: (ids: string[]) => void;
  onUnLicenseItem?: (ids: string[]) => void;
  onRestoreItem?: TRowAction;
  onRestoreVersion?: TRowAction;
  onViewItem?: TRowAction;
  onDetailItem?: TRowAction;
  onClickItemRow?: TRowAction;
  onStopExecution?: TRowAction;
  onResumeExecution?: TRowAction;
  onContinueExecution?: TRowAction;
  onReplicateItem?: TRowAction;
  extraActions?: ITableAction[];
  searchTerm?: string;
  outerFilterParameters?: any;
  isLoading?: boolean;
  isTableChart?: boolean;
  noCardAround?: boolean;
  emptyMessage?: string;
  hasCheckBox?: boolean;
  onCheckChanged?: any;
  onSwitchChanged?: any;
  checkedRows?: string[];
  attrToHide?: string[];
  showSyntheticRow?: boolean;
  clickableRows?: boolean;
  filterParams: any;
  bulkActions?: any[];
  filterDropdownManager?: ReactNode;
  searchBar?: ReactNode;
  tableMenu?: ReactNode;
  columnMenu?: ReactNode;
  handleClearFilters?: () => void;
  showColumnMenu?: boolean;
  dataCount: number;
  totalCount: number;
  defaultRowsPerPage?: number;
  defaultSorting?: ITableSorting;
  onChangeSorting?: (sorting: ITableSorting) => void;
  loadNextPage?: (page: ILoadPage) => void;
  handleSortRowsBackend?: (sorting: ITableSorting) => any;
  handleChangeRowsPerPage?: (pageSize: number) => void;
  backendSearch?: boolean;
  paginationDescription?: {
    description: string;
    tooltip?: string;
  };
  onDownloadCSV?: () => void;
  onDownloadExcel?: () => void;
};

export interface ITableSorting {
  orderBy: string;
  order: "asc" | "desc";
}

export const ROWS_PER_PAGE = 50;

interface ICustomTableStates {
  currentPage: number;
  rowsPerPage: number;
  sorting: ITableSorting;
  checkBoxStatus: 0 | 1 | 2;
  checkedRowsState: string[];
  pictureOpened?: string;
  gpsCoordinates?: { lng: number; lat: number };
  rows: any[];
  tableLayout: "auto" | "fixed";
  tableScroll: {
    x: number;
    y: number;
  };
}

/**
 * Table component. Re-usable Table component
 */
export class CustomTable extends Component<
  TCustomTableProps,
  ICustomTableStates
> {
  public static defaultProps: TCustomTableProps = {
    rowData: [],
    hasCheckBox: false,
    checkedRows: [],
    searchTerm: "",
    outerFilterParameters: {},
    onCheckChanged: () => true,
    attrToHide: [],
    filterParams: {},
    defaultSorting: {
      orderBy: "name",
      order: "asc",
    },
    classes: undefined,
    columnTypes: [],
    loadNextPage: undefined,
    dataCount: 0,
    totalCount: 0,
    defaultRowsPerPage: ROWS_PER_PAGE,
    isLoading: false,
    isTableChart: false,
  };

  constructor(props: TCustomTableProps) {
    super(props);

    const {
      defaultSorting,
      onChangeSorting,
      columnTypes,
      rowData,
      checkedRows,
    } = props || {};

    const initOrderBy =
      defaultSorting?.orderBy || getDefaultOrderBy(columnTypes);
    const columnType = getOrderByColumnType(initOrderBy, columnTypes);
    const initOrder = defaultSorting?.order || getDefaultOrder(columnType);

    const sortedRows = getSortedRows({
      rows: rowData,
      sorting: {
        order: initOrder,
        orderBy: initOrderBy,
      },
      columnTypes,
    });

    const checkBoxStatus =
      !checkedRows || _.isEmpty(checkedRows)
        ? 0
        : checkedRows.length === rowData.length
        ? 1
        : 2;

    this.state = {
      currentPage: 0,
      rowsPerPage: this.props.defaultRowsPerPage ?? ROWS_PER_PAGE,
      sorting: {
        orderBy: initOrderBy,
        order: initOrder,
      },
      checkBoxStatus,
      checkedRowsState: checkedRows || [],
      pictureOpened: undefined,
      gpsCoordinates: undefined,
      tableScroll: getScrollPosition(".tableScroll"),
      tableLayout: "auto",
      rows: sortedRows,
    };

    if (onChangeSorting) {
      onChangeSorting({ order: initOrder, orderBy: initOrderBy });
    }
  }

  resetCurrentPage = (activePage = 0) => {
    this.setState({ currentPage: activePage });
  };

  componentDidUpdate(prevProps: TCustomTableProps) {
    if (!fastCompare(prevProps.rowData, this.props.rowData)) {
      if (this.props.handleSortRowsBackend) {
        this.setState({ rows: this.props.rowData });
      } else {
        this.handleSorting(this.state.sorting);
      }
    }

    // TODO: hack to reset page number when items change
    if (prevProps.dataCount !== this.props.dataCount) {
      this.resetCurrentPage();
    }
  }

  handleSorting = async (sorting: ITableSorting) => {
    const { rowData, columnTypes, onChangeSorting, handleSortRowsBackend } =
      this.props;

    const { orderBy, order } = sorting;
    const { orderBy: oldOrderBy, order: oldOrder } = this.state.sorting;
    if (orderBy !== oldOrderBy || order !== oldOrder) {
      this.resetCurrentPage();
    }

    let sortedRows;

    if (handleSortRowsBackend) {
      await handleSortRowsBackend(sorting);
      this.setState({ sorting });

      // on backend sorting, raw data is already sorted  // TODO: <= yes, but only after handleSortRowsBackend returns ?
    } else {
      sortedRows = getSortedRows({
        rows: rowData,
        sorting,
        columnTypes,
      });
      this.setState({ rows: sortedRows, sorting });
    }

    if (onChangeSorting) {
      onChangeSorting(sorting);
    }
  };

  /**
   * Handles Changing page of the current page
   * @param {Object} event Event object
   * @param {Number} currentPage Current Page index of the Table (starts at 0)
   * */
  handleCurrentPageChange = (_event: any, pageIndexToShow: number): void => {
    const { rowsPerPage, currentPage } = this.state;
    const { loadNextPage, dataCount, rowData } = this.props;

    this.setState({ currentPage: pageIndexToShow });

    const nextPageToLoad = pageIndexToShow + 2;
    const loadedPageCount = Math.floor(_.size(rowData) / rowsPerPage);

    // Page already loaded (we can only move to higher pages if wwe loaded the previous ones)
    if (pageIndexToShow < currentPage || loadedPageCount >= nextPageToLoad) {
      return;
    }

    // We assume first 2 pages are loaded on initialization
    // So we can load the page N+2

    const lastPageNumber = Math.ceil(dataCount / rowsPerPage);

    if (nextPageToLoad > lastPageNumber) {
      return;
    }

    if (loadNextPage) {
      loadNextPage({ pageNumber: nextPageToLoad, pageSize: rowsPerPage });
    }
  };

  /**
   * Handle change rows per page to display in table
   * @param {Object} e Event Object
   * */
  onRowsPerPageChange = (e: any): void => {
    const { handleChangeRowsPerPage } = this.props;
    const rowsPerPage = e.target.value;

    this.setState({ rowsPerPage });

    if (handleChangeRowsPerPage) {
      handleChangeRowsPerPage(rowsPerPage);
      this.resetCurrentPage(); // TODO: Find way to recalculate correctly the data to avoid to back each time the rowPerPage change
    }
  };

  /**
   * Handles Tablerow check change on table items to change the status of the checkbox on the table header.
   * The id is used to identify which table row is currently checked (or unchecked)
   * @param {String} item
   * @param {String} isChecked
   * @returns {Boolean}
   */
  handleOnCheckChanged = (itemId: string, isChecked: boolean): void => {
    const { checkedRowsState } = this.state;
    const { rowData } = this.props;
    let newCheckedRows: string[] = [];
    if (isChecked) {
      newCheckedRows = checkedRowsState;
      newCheckedRows.push(itemId);
    } else {
      checkedRowsState.forEach((element) => {
        if (element !== itemId) {
          newCheckedRows.push(element);
        }
      });
    }
    this.setState({
      checkedRowsState: newCheckedRows,
      checkBoxStatus:
        newCheckedRows.length === 0
          ? 0
          : newCheckedRows.length === rowData.length
          ? 1
          : 2,
    });
    const { onCheckChanged } = this.props;
    onCheckChanged(newCheckedRows);
  };

  /**
   * Handles the selection of all data items in the table
   */
  handleOnSelectAll = (checked: boolean): void => {
    const { onCheckChanged, searchTerm = "", columnTypes } = this.props;
    const { currentPage, rowsPerPage, rows } = this.state;

    if (!checked) {
      this.setState({ checkedRowsState: [], checkBoxStatus: 0 });
      onCheckChanged([]);
      return;
    }

    const data = handleExactSearch({ rows, searchTerm, columnTypes });

    let selectedRows = [];
    if (rowsPerPage < data.length) {
      // we only select the result in first page
      selectedRows = data.filter((_d, i) => {
        if (
          currentPage * rowsPerPage <= i &&
          (currentPage + 1) * rowsPerPage > i
        ) {
          return true;
        }
        return false;
      });
    } else {
      // we select every rows in display
      selectedRows = data;
    }

    const checkedRowsState = selectedRows.map((n: any) => getRowId(n));
    const checkBoxStatus = rows.length === selectedRows.length ? 1 : 2;

    this.setState({ checkedRowsState, checkBoxStatus });
    onCheckChanged(checkedRowsState);
  };

  emptyTable = (data: any[]) => {
    const {
      searchTerm,
      filterParams,
      outerFilterParameters,
      handleClearFilters,
    } = this.props;

    const searchFilter =
      searchTerm && searchTerm.length && data.length === 0
        ? "search"
        : data.length === 0 /*filterData.length*/ &&
          (checkFilterValueLength(filterParams).length ||
            Object.keys(outerFilterParameters).length)
        ? "filter"
        : undefined;

    if (data.length === 0) {
      return (
        <BrowserRouter>
          <EmptyTable
            searchFilter={searchFilter}
            searchTerm={searchTerm}
            onClear={handleClearFilters}
          />
        </BrowserRouter>
      );
    }
  };

  render() {
    const lang = getLangObject();
    const {
      classes,
      columnTypes,
      onEditItem,
      onDeleteItem,
      onArchiveItem,
      onRestoreItem,
      onRestoreVersion,
      onViewItem,
      onDetailItem,
      onClickItemRow,
      onStopExecution,
      onResumeExecution,
      onContinueExecution,
      onReplicateItem,
      onLicenseItem,
      onUnLicenseItem,
      extraActions,
      bulkActions,
      isLoading,
      emptyMessage,
      hasCheckBox,
      onSwitchChanged,
      searchTerm,
      attrToHide,
      showSyntheticRow,
      clickableRows,
      filterParams,
      searchBar,
      filterDropdownManager,
      tableMenu,
      columnMenu,
      isTableChart,
      noCardAround,
      showColumnMenu,
      dataCount,
      totalCount,
      handleClearFilters,
      backendSearch,
      paginationDescription,
      onDownloadCSV,
      onDownloadExcel,
      ...rest
    } = this.props;

    delete rest.onCheckChanged;

    const {
      currentPage,
      rowsPerPage,
      checkBoxStatus,
      pictureOpened,
      gpsCoordinates,
      checkedRowsState,
      tableScroll,
      tableLayout,
      sorting: { orderBy, order },
      rows,
    } = this.state;

    const data = backendSearch
      ? rows
      : handleExactSearch({ rows, searchTerm, columnTypes });

    const progressData: any[] = [];

    const formatedColumnTypes = columnTypes.map((c) => {
      const match = c.name.match(/\[([A-z\s]*)\] ([A-z\s]*)/);
      if (match) {
        c.label = match[2];
        switch (match[1].toUpperCase()) {
          case "PERCENTAGE":
            c.type = TABLE_COLUMN_TYPE.PERCENTAGE;
            break;
          case "PROGRESS":
            c.type = TABLE_COLUMN_TYPE.PROGRESS;
            progressData[c.name] = {
              min: [...data].sort((a, b) => {
                return a[c.name] - b[c.name];
              })[0][c.name],
              max: [...data].sort((a, b) => {
                return b[c.name] - a[c.name];
              })[0][c.name],
            };
        }
      }
      return c;
    });

    const Container = noCardAround ? DivContainer : Paper;

    function handleTableRows(info: LabelDisplayedRowsArgs) {
      return labelTableRowsRange({ info, lang });
    }
    const donwloadOptions = [];
    if (onDownloadCSV) {
      donwloadOptions.push({
        label: lang.actions.downloadCSV,
        onClick: onDownloadCSV,
        icon: <DownloadIcon />,
      });
    }

    if (onDownloadExcel) {
      donwloadOptions.push({
        label: lang.actions.downloadExcel,
        onClick: onDownloadExcel,
        icon: <DownloadIcon />,
      });
    }

    return (
      <div className={classes.root}>
        {/* TODO: we should reuse the same dialog as InputGPS (in VIEW mode), to be able to read coordinates value, and copy them for instance */}
        <CustomFullScreenDialog
          open={gpsCoordinates ? true : false}
          onClose={() => this.setState({ gpsCoordinates: undefined })}
        >
          {gpsCoordinates ? (
            <MapWithMarker markerPosition={gpsCoordinates} />
          ) : null}
        </CustomFullScreenDialog>

        <PictureDialog
          open={pictureOpened ? true : false}
          onClose={() => this.setState({ pictureOpened: undefined })}
          url={pictureOpened}
        />

        <Container className={classes.container}>
          <div className={classes.AboveTable}>
            <div className={classes.HeaderContainer}>
              <div className={classes.ActionButtonsContainer}>
                {hasCheckBox && (
                  <CustomTableActionsHeader
                    checkedRows={checkedRowsState}
                    data={data}
                    onSelectAll={this.handleOnSelectAll}
                    onEditItem={onEditItem}
                    onDeleteItem={onDeleteItem}
                    onArchiveItem={onArchiveItem}
                    onRestoreItem={onRestoreItem}
                    onLicenseItem={onLicenseItem}
                    onUnLicenseItem={onUnLicenseItem}
                    onRestoreVersion={onRestoreVersion}
                    onViewItem={onViewItem}
                    onStopExecution={onStopExecution}
                    onResumeExecution={onResumeExecution}
                    onContinueExecution={onContinueExecution}
                    onReplicateItem={onReplicateItem}
                    bulkActions={bulkActions}
                    checkBoxStatus={checkBoxStatus}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {searchBar && (
                    <Box marginLeft="8px" width="250px" flexShrink="0">
                      {searchBar}
                    </Box>
                  )}
                  {filterDropdownManager && (
                    <Box
                      padding="6px 0"
                      display="flex"
                      alignItems="center"
                      marginRight="16px"
                    >
                      {filterDropdownManager}
                    </Box>
                  )}
                </div>

                <div>
                  {(onDownloadCSV || onDownloadExcel) && (
                    <MenuButton
                      label={
                        donwloadOptions.length === 1
                          ? donwloadOptions[0].label
                          : lang.genericTerms.downloadOptions
                      }
                      color="inherit"
                      icon={<DownloadIcon />}
                      fontColor={primaryColor}
                      options={donwloadOptions}
                    />
                  )}
                </div>
              </div>
            </div>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              {showColumnMenu && <div>{columnMenu}</div>}
              {tableMenu}
            </Box>
          </div>

          {isLoading ? (
            <TableSkeletonLoader
              columnsNumber={columnTypes.length}
              showCreateButton={false}
              showTableSearch={false}
            />
          ) : (
            <Box
              className={classNames(
                classes.TableContainer,
                isTableChart && "isTableChart",
                "tableScroll"
              )}
              onScroll={() => {
                this.setState({
                  tableScroll: getScrollPosition(".tableScroll"),
                });
              }}
            >
              <Table
                id={onDetailItem ? "table-clickable" : "table"}
                style={{ tableLayout }}
              >
                <CustomTableHeader
                  columnNames={columnTypes.map((c) => ({
                    name: c.name,
                    title: c.label,
                    unsortable: c.unsortable,
                  }))}
                  handleSorting={this.handleSorting}
                  order={order}
                  orderBy={orderBy}
                  hasCheckBox={hasCheckBox}
                  hasActions={Boolean(
                    onDetailItem ||
                      onArchiveItem ||
                      onEditItem ||
                      onRestoreItem ||
                      onDeleteItem ||
                      onReplicateItem ||
                      onStopExecution ||
                      onResumeExecution ||
                      onContinueExecution ||
                      !_.isEmpty(extraActions)
                  )}
                  checkBoxStatus={checkBoxStatus}
                  onSelectAll={this.handleOnSelectAll}
                  sticky
                  scrollValue={tableScroll}
                  tableLayout={tableLayout}
                  setTableLayout={(tableLayout) =>
                    this.setState({ tableLayout })
                  }
                />
                {data.length > 0 && (
                  <CustomTableBody
                    totalCount={totalCount}
                    dataCount={dataCount}
                    filterParams={filterParams}
                    rowData={data}
                    rowsPerPage={rowsPerPage}
                    columnTypes={formatedColumnTypes}
                    page={currentPage}
                    checkedRows={checkedRowsState}
                    clickableRows={clickableRows}
                    onDetailItem={onDetailItem}
                    onEditItem={onEditItem}
                    onReplicateItem={onReplicateItem}
                    onViewItem={onViewItem}
                    onArchiveItem={onArchiveItem}
                    onRestoreItem={onRestoreItem}
                    onDeleteItem={onDeleteItem}
                    onStopExecution={onStopExecution}
                    onResumeExecution={onResumeExecution}
                    onContinueExecution={onContinueExecution}
                    onClickItemRow={onClickItemRow}
                    extraActions={extraActions}
                    emptyMessage={emptyMessage}
                    hasCheckBox={hasCheckBox}
                    onCheckChanged={this.handleOnCheckChanged}
                    onSwitchChanged={onSwitchChanged}
                    attrToHide={attrToHide}
                    showSyntheticRow={showSyntheticRow}
                    onOpenPicture={(pictureOpened) =>
                      this.setState({ pictureOpened })
                    }
                    onOpenGPS={(gpsCoordinates) =>
                      this.setState({ gpsCoordinates })
                    }
                    progressData={progressData}
                    scrollValue={tableScroll}
                    handleClearFilters={handleClearFilters}
                  />
                )}
              </Table>

              {data.length === 0 && this.emptyTable(data)}
            </Box>
          )}
        </Container>

        {!isLoading && (
          <div className={classes.PaginationContainer}>
            <TablePagination
              component={"div"}
              classes={{
                root: classes.TablePaginationRoot,
                toolbar: classes.TablePaginationToolbar,
                spacer: classes.TablePaginationSpacer,
                caption: classes.TablePaginationCaption,
                select: classes.TablePaginationSelect,
                actions: classes.TablePaginationActions,
              }}
              count={dataCount}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={lang.components.table.rowsPerPage}
              page={currentPage}
              onPageChange={this.handleCurrentPageChange}
              onRowsPerPageChange={this.onRowsPerPageChange}
              labelDisplayedRows={handleTableRows}
              rowsPerPageOptions={[10, 25, 50]}
            />

            {paginationDescription && (
              <TablePaginationDescription
                description={paginationDescription.description}
                tooltip={paginationDescription.tooltip}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const DivContainer = (props: any) => (
  <div className={props.className}>{props.children}</div>
);

export const checkFilterValueLength = (objet: any) => {
  const booleanValue: any[] = [];

  if (objet && objet[FILTER_TAG.MORE_FILTER]) {
    _.map(objet[FILTER_TAG.MORE_FILTER], (element) => {
      if (
        objet[element] &&
        (_.isObject(objet[element]) || objet[element].length)
      ) {
        booleanValue.push(element);
      }
    });
  }

  return booleanValue;
};

export default withStyles(styles as any)(CustomTable);
