import _ from "lodash";

import {
  IHttpRequest,
  IJobScript,
  INotification,
  IStream,
  ITransformation,
  STEP_TYPE,
} from "model/entities/Job";
import {
  IActivity,
  IStepOverview,
  IWorkflow,
  IWorkflowStep,
} from "model/entities/Workflow";

import { computeTerminaisonTime } from "../subcategories/jobs/Jobscreen/timer/utils";
import {
  getJobsFromType,
  TAnyJob,
} from "../subcategories/jobs/Jobscreen/utils";
import { canParseJSON } from "../subcategories/workflows/WorkflowStepInfo/utils";

export interface IAddStepDetailsToWorkflow {
  activities: IActivity[];
  stepsOverview?: IStepOverview[];
  jobs: TAnyJob[];
  workflow?: IWorkflow;
}

export const addStepDetailsToWorkflow = (
  args: IAddStepDetailsToWorkflow
): IWorkflow => {
  if (!args.workflow) return {} as IWorkflow;
  const stepsArrays = args.workflow.steps;
  const steps = _.map(stepsArrays, (step) => {
    return addDetailsToStep({ ...args, step });
  });
  return {
    ...args.workflow,
    steps,
  };
};

interface IAddDetailsToStep extends IAddStepDetailsToWorkflow {
  step: IWorkflowStep;
}

export const addDetailsToStep = ({
  workflow,
  activities,
  step,
  jobs,
  stepsOverview,
}: IAddDetailsToStep) => {
  // TODO: stepOverviewLinkedToStep -> THIS SHOULD NOT BE HERE
  // - steps belong to the "config" realm (the definition of the workflow in abstract)
  // - step overviews belong to the "execution" realm (what actually happened)
  const stepOverviewLinkedToStep = _.find(
    stepsOverview,
    (overview: IStepOverview) => overview.step_id === step?.id
  );

  const type = step["type"] ?? step["step_type"];
  switch (type) {
    case STEP_TYPE.ACTIVITY:
      return {
        ...step,
        title: _.find(activities, (act: any) => act.id === step.schema_id)
          ?.name,
      };
    case STEP_TYPE.MAINTENANCE:
      return {
        ...step,
        title: step.schema_id,
      };
    case STEP_TYPE.TRANSFORMATION: {
      const transformations = getJobsFromType(
        jobs,
        STEP_TYPE.TRANSFORMATION
      ) as ITransformation[];

      const currentTransfo = _.find(
        transformations,
        (transfo: any) => transfo.id === step.schema_id
      );

      return {
        ...step,
        title: currentTransfo?.title,
      };
    }
    case STEP_TYPE.NOTIFICATION:
      const notifications = getJobsFromType(
        jobs,
        STEP_TYPE.NOTIFICATION
      ) as INotification[];
      return {
        ...step,
        title: notifications.find((notif: any) => notif.id === step.schema_id)
          ?.title,
      };
    case STEP_TYPE.STREAM:
      const streams = getJobsFromType(jobs, STEP_TYPE.STREAM) as IStream[];
      return {
        ...step,
        title: streams.find((stream: any) => stream.id === step.schema_id)
          ?.destination_table,
      };
    case STEP_TYPE.SCRIPT:
      const scripts = getJobsFromType(jobs, STEP_TYPE.SCRIPT) as IJobScript[];
      return {
        ...step,
        title: scripts.find((script: any) => script.id === step.schema_id)
          ?.title,
      };
    case STEP_TYPE.HTTP_REQUEST:
      const http_requests = getJobsFromType(
        jobs,
        STEP_TYPE.HTTP_REQUEST
      ) as IHttpRequest[];
      return {
        ...step,
        title: http_requests.find(
          (request: any) => request.id === step.schema_id
        )?.name,
      };
    case STEP_TYPE.TIMER:
      let params = [] as any;
      if (
        stepOverviewLinkedToStep?.parameters &&
        canParseJSON(stepOverviewLinkedToStep?.parameters)
      ) {
        params = JSON.parse(stepOverviewLinkedToStep.parameters);
      } else {
        const sourceChain = _.find(
          workflow?.chainings,
          (c: any) => c.destination_step === step.id
        );

        if (!sourceChain) {
          if (step.id === workflow?.starting_step_id) {
            params = workflow.first_step_params || [];
          }
        } else {
          params = sourceChain.destination_params || [];
        }
      }

      const date = stepOverviewLinkedToStep?.started_at
        ? new Date(stepOverviewLinkedToStep.started_at)
        : undefined;

      return {
        ...step,
        title: type,
        description: date
          ? computeTerminaisonTime(
              date,
              params.find((o: any) => o.key === "absolute")?.value || [],
              params.find((o: any) => o.key === "relative")?.value || []
            )
          : "",
      };
    default:
      return { ...step, title: type };
  }
};
