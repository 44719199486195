import _ from "lodash";

import { getLangObject } from "lang/utils";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";
import { DEFAULT_DATE_FILTER, IClient } from "model/entities/Client";
import { ITeamSelector } from "model/entities/Team";

import { NUMBER_FILTER } from "./../../model/entities/Client";
import hooks from "./FilterModals/components/DateFilter.hooks";
import {
  convertHierarchyTypes,
  getHierarchyFromTeams,
  getLabelsFromHierarchy,
} from "./FilterModals/components/utils";
import FILTER_TYPE, { FILTER_TAG } from "./TypeFilter";
import { applyHierarchiesFilterDependenciesOnTeams } from "./utils/applyDependencies";

interface IBuildMCOL {
  label: string;
  tag: string;
  listId: string;
  options: IOption[];
  value: string[];
}
export const buildMultipleChoiceOnListFilter = ({
  label,
  listId,
  options,
  tag,
  value,
}: IBuildMCOL) => {
  return {
    label,
    tag,
    type: FILTER_TYPE.MULTIPLE_CHOICE_ON_LIST,
    listId,
    value,
    options,
  };
};

export const buildTextFilter = (
  label: string,
  tag: string,
  defaultValue?: string
): IFilter => {
  return {
    type: FILTER_TYPE.TEXT,
    label,
    tag,
    value: defaultValue ? defaultValue : undefined,
    isSecondaryFilter: false,
    singleSelection: false,
  };
};

export const buildMultipleChoiceFilter = (
  options: IOption[],
  label: string,
  tag: string,
  defaultValue?: string[],
  isSecondaryFilter?: boolean
): IFilter => {
  return {
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    label,
    tag,
    value: defaultValue ? defaultValue : [],
    isSecondaryFilter: isSecondaryFilter ? isSecondaryFilter : false,
    options,
    singleSelection: false,
  };
};
export const buildListItemFilter = (
  options: IOption[],
  tag: string,
  listId: string,
  label: string,
  defaultValue?: string[],
  singleSelection?: boolean
): IFilter => {
  return {
    type: FILTER_TYPE.MULTIPLE_CHOICE_ON_LIST,
    label: label,
    tag,
    value: defaultValue ?? [],
    listId,
    isSecondaryFilter: false,
    singleSelection: singleSelection,
    options,
  };
};

export const buildSingleChoiceFilter = (
  options: IOption[],
  label: string,
  tag: string,
  defaultValue?: string[]
): IFilter => {
  return {
    type: FILTER_TYPE.SINGLE_CHOICE,
    label,
    tag,
    value: defaultValue ? defaultValue : [],
    isSecondaryFilter: false,
    options,
  };
};

export const buildTeamsAndHierarchyFilter = (
  teams: ITeamSelector[],
  client: IClient,
  teamsSelected: string[],
  hierarchiesFilter?: string[],
  isTeamsSecondaryFilter?: boolean,
  isHierarchiesSecondaryFilter?: boolean
): IFilter[] => {
  const activeTeams = teams.filter(
    (t) => !t.hasOwnProperty("active") || t.active
  );
  const filters: IFilter[] = [];
  filters.push(
    buildTeamsFilter(
      activeTeams,
      client.hierarchy_dependencies,
      hierarchiesFilter,
      isTeamsSecondaryFilter
    )
  );
  // if there is a hierarchy, add the hierarchy filter
  if (
    client.meta_hierarchy_dependencies &&
    Object.keys(client.meta_hierarchy_dependencies).find(
      (lev) => client.meta_hierarchy_dependencies[lev].level_type_number >= 0
    )
  ) {
    filters.push(
      buildHierarchyFilter(
        client,
        teamsSelected ? teamsSelected : activeTeams.map((t) => t.id),
        activeTeams,
        isHierarchiesSecondaryFilter
      )
    );
  }
  if (client.meta_hierarchy_dependencies) {
    Object.keys(client.meta_hierarchy_dependencies).forEach((k) => {
      if (
        client.meta_hierarchy_dependencies[k] &&
        client.meta_hierarchy_dependencies[k].level_type_number === -1
      ) {
        const labs = getLabelsFromHierarchy(
          activeTeams.map((t) => t.id),
          activeTeams,
          client.meta_hierarchy_dependencies,
          client.meta_hierarchy_dependencies[k]
        );
        if (labs.length > 0) {
          filters.push(
            buildMultipleChoiceFilter(
              labs.map((l: any) => ({ key: l, label: l })),
              client.meta_hierarchy_dependencies[k].level_type_name,
              FILTER_TAG.LABELS.concat(
                "_",
                client.meta_hierarchy_dependencies[k].level_type_name
                  .toLowerCase()
                  .replaceAll(" ", "_")
              ),
              [],
              isHierarchiesSecondaryFilter
            )
          );
        }
      }
    });
  }
  return filters;
};

const buildTeamsFilter = (
  allTeams: ITeamSelector[],
  hierarchy: any[],
  hierarchiesFilter?: string[],
  isSecondaryFilter?: boolean
): IFilter => {
  const lang = getLangObject();
  let teams = allTeams;

  //teams by hierarchy filter
  if (!_.isEmpty(hierarchiesFilter)) {
    teams = applyHierarchiesFilterDependenciesOnTeams(teams, hierarchiesFilter);
  }

  const teamsOptions = teams.map((t) => ({
    label: t.name,
    key: t.id,
  }));
  const teamFilter = buildMultipleChoiceFilter(
    teamsOptions,
    lang.components.filters.teams,
    FILTER_TAG.TEAMS,
    [],
    isSecondaryFilter
  );

  // add the hierarchy informations
  if (hierarchy) {
    teamFilter.hierarchy = hierarchy;
  }
  return teamFilter;
};

export const buildHierarchyFilter = (
  client: IClient,
  teamsSelected: string[],
  fullTeams: ITeamSelector[],
  isSecondaryFilter?: boolean
): IFilter => {
  const lang = getLangObject();

  let clientHierarchy = client.hierarchy_dependencies;

  let teamsFound = fullTeams;

  if (!_.isEmpty(teamsSelected)) {
    teamsFound = _.filter(fullTeams, (team: ITeamSelector) =>
      _.includes(teamsSelected, team.id)
    );
    const teamsLevelsPicked = teamsFound.map((objet) => {
      return _.pickBy(objet, (value, key) => {
        return key.startsWith("level_") && value !== null;
      });
    });
    const teamsLevelsPickedValue = _.uniq(
      _.flatMap(_.map(teamsLevelsPicked, (objet) => _.values(objet)))
    );

    clientHierarchy = _.filter(clientHierarchy, (hiera) =>
      _.includes(teamsLevelsPickedValue, hiera.level_name)
    );
  }

  const hierarchy = convertHierarchyTypes(
    clientHierarchy,
    client.meta_hierarchy_dependencies
  );

  const value = getHierarchyFromTeams(
    teamsSelected,
    fullTeams,
    client.meta_hierarchy_dependencies
  );

  return {
    type: FILTER_TYPE.HIERARCHY,
    label: lang.components.filters.levels,
    tag: FILTER_TAG.HIERARCHY,
    hierarchy,
    value,
    isSecondaryFilter,
    options: [] as IOption[],
    singleSelection: false,
  };
};
export const buildNumberFilter = (
  label: string,
  tag: string,
  numberFilterType?: NUMBER_FILTER,
  defaultValue?: string
): IFilter => {
  let value;
  if (numberFilterType) {
    value = {
      search_value: defaultValue ? defaultValue : undefined,
      number_filter_type: numberFilterType,
    };
  }
  return {
    type: FILTER_TYPE.NUMBER,
    label,
    tag,
    value,
    isSecondaryFilter: false,
    options: [] as IOption[],
    singleSelection: false,
  };
};

export const buildBooleanFilter = (
  label: string,
  tag: string,
  defaultValue?: string[]
): IFilter => {
  return {
    type: FILTER_TYPE.BOOLEAN,
    label,
    tag,
    value: defaultValue ? defaultValue : [],
    isSecondaryFilter: false,
    options: [
      {
        label: "TRUE",
        key: "true",
        value: true,
      },
      {
        label: "FALSE",
        key: "false",
        value: false,
      },
    ] as IOption[],
    singleSelection: true,
  };
};

export const buildDateFilter = (
  label: string,
  tag: string,
  dateType?: DEFAULT_DATE_FILTER
): IFilter => {
  const { getDateRange } = hooks;
  let value;
  if (dateType) {
    const { startDate: start, endDate: end } = getDateRange(
      new Date(),
      dateType
    );
    value = {
      startDate: start,
      endDate: end,
      dateType,
    };
  }
  return {
    type: FILTER_TYPE.DATE,
    label,
    tag,
    value,
    isSecondaryFilter: false,
    options: [] as IOption[],
    singleSelection: false,
  };
};
export const getStartAndEndDateFromDateType = (
  dateType: DEFAULT_DATE_FILTER
): { startDate: Date; endDate: Date } => {
  const startDate = new Date(new Date().setHours(0, 0, 0, 0)); // = start of the day
  let dayNbBeforeTodayForStartDay = 0,
    dayNbAfterStartDate = 0;
  switch (dateType) {
    case DEFAULT_DATE_FILTER.YESTERDAY: {
      dayNbBeforeTodayForStartDay = 1;
      dayNbAfterStartDate = 1;
      break;
    }
    case DEFAULT_DATE_FILTER.TODAY: {
      dayNbBeforeTodayForStartDay = 0;
      dayNbAfterStartDate = 1;
      break;
    }
    case DEFAULT_DATE_FILTER.LAST_7_DAYS: {
      dayNbBeforeTodayForStartDay = 7;
      dayNbAfterStartDate = 7;
      break;
    }
    case DEFAULT_DATE_FILTER.LAST_30_DAYS: {
      dayNbBeforeTodayForStartDay = 30;
      dayNbAfterStartDate = 30;
      break;
    }
    //from here
    case DEFAULT_DATE_FILTER.LAST_MONTH: {
      const MyEndDate = new Date();
      MyEndDate.setMonth((new Date().getMonth() || 12) - 1);
      MyEndDate.setDate(0);
      MyEndDate.setHours(23, 59, 59);
      dayNbBeforeTodayForStartDay = Math.floor(
        (startDate.getTime() - MyEndDate.getTime()) / (1000 * 3600 * 24)
      );
      dayNbAfterStartDate = Math.floor(
        (startDate.getTime() - MyEndDate.getTime()) / (1000 * 3600 * 24)
      );
      break;
    }
    case DEFAULT_DATE_FILTER.THIS_MONTH: {
      dayNbBeforeTodayForStartDay = new Date().getDate();
      dayNbAfterStartDate = new Date().getDate();
      break;
    }
    case DEFAULT_DATE_FILTER.LAST_WEEK: {
      const myEndDate = new Date();
      myEndDate.setHours(-24 * (startDate.getDay() || 7));
      myEndDate.setHours(23, 59, 59);
      dayNbBeforeTodayForStartDay = Math.floor(
        (startDate.getTime() - myEndDate.getTime()) / (1000 * 3600 * 24)
      );
      dayNbAfterStartDate = Math.floor(
        (startDate.getTime() - myEndDate.getTime()) / (1000 * 3600 * 24)
      );
      break;
    }
    case DEFAULT_DATE_FILTER.THIS_WEEK: {
      dayNbBeforeTodayForStartDay = new Date().getDay();
      dayNbAfterStartDate = new Date().getDay();
      break;
    }
  }

  startDate.setDate(startDate.getDate() - dayNbBeforeTodayForStartDay);
  const endDate = new Date(
    startDate.getTime() + 1000 * 60 * 60 * 24 * dayNbAfterStartDate - 1
  ); // = 1 ms before the end of the day
  return {
    startDate,
    endDate,
  };
};

export const buildMoreFilter = (
  options: IOption[],
  label?: string
): IFilter => {
  const lang = getLangObject();
  return {
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    label: label || lang.components.filters.more,
    tag: FILTER_TAG.MORE_FILTER,
    value: [],
    isSecondaryFilter: false,
    options: options,
    singleSelection: false,
  };
};
