import _ from "lodash";

import { TTimelineData } from "model/entities/Dashboard";

export const sortVisitChunksByDate = (
  arrayOfArrays: Array<Array<TTimelineData>>
) => {
  return _.sortBy(arrayOfArrays, (arr) => {
    const firstElement = _.first(arr);
    if (firstElement && firstElement.date) {
      return new Date(firstElement.date);
    }
    return new Date(0);
  });
};
