import { CSSProperties, useState } from "react";

import {
  Direction,
  DragDropContext,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";

interface IDragAndDropContainer {
  children: JSX.Element[]; // IMPORTANT: children (in array) must have a key
  direction?: Direction;
  // Use this prop to change the order of the children passed to this component
  onReorderChildren?: (children: JSX.Element[]) => void;
  style?: CSSProperties;
  containerProps?: React.ComponentProps<"div">;
}

const DragAndDropContainer = ({
  children,
  onReorderChildren,
  direction,
  style = {},
  containerProps = {},
}: IDragAndDropContainer) => {
  const [isDragging, setIsDragging] = useState(false);

  // See example in docs
  const reOrderDrag = (items: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((item, i) => ({
      ...item,
      index: i,
    }));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newChildren = reOrderDrag(
      children,
      result.source.index,
      result.destination.index
    );

    if (onReorderChildren) onReorderChildren(newChildren);
    setIsDragging(false);
  };

  return (
    <DragDropContext
      onDragStart={() => setIsDragging(true)}
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId="droppable" direction={direction}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: direction === "horizontal" ? "row" : "column",
              overflow: "auto",
              paddingBottom: direction === "vertical" && isDragging ? 40 : 0,
              ...style,
            }}
            {...containerProps}
          >
            {children.map((child, index) => (
              <Draggable
                key={child.key}
                draggableId={String(child.key)}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {child}
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropContainer;
