import { JOB_POSSIBLE_TO_CONFIGURE, STEP_TYPE } from "fieldpro-tools";
import _ from "lodash";

import { FormSection } from "components/Forms/Form";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";

import VisitsManagement from "../../Tabs/VisitsManagement";
import OrdersManagement from "./OrdersManagementTab";
import PredictionManagement from "./PredictionManagement";
import RouteManagement from "./RouteManagement";
import TerritoriesManagement from "./TerritoriesManagement";

export interface IAddonsTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  handleInputChange: (value: any, name: string) => void;
  handleChangeAvailableJobs: (value: IOption<STEP_TYPE>[]) => void;
  onChangeAttributeValues: (
    name: string,
    value: any,
    shouldRerender?: boolean
  ) => void;
  handleSelectChange: (value: string, name: string) => void;
  attributes: IClient;
  onSave?: () => void;
  onDiscard?: () => void;
  onCreate?: () => void;
}

export const AddonsTab: React.FunctionComponent<IAddonsTabProps> = ({
  handleInputChange,
  onChangeAttributeValues,
  attributes,
  onSave,
  is_route_management_enabled,
  route_management_access_roles,
  onDiscard,
  available_jobs,
  handleChangeAvailableJobs,
  handleSelectChange,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;
  return (
    <FormSection
      onSave={onSave}
      disableSave={
        is_route_management_enabled && _.isEmpty(route_management_access_roles)
      }
      onDiscard={onDiscard}
    >
      {({ viewMode }) => (
        <>
          <InputMultipleSelect
            viewMode={viewMode}
            name={"available_jobs"}
            defaultValue={_.map(available_jobs ?? [], (j) => ({
              key: j,
              label: j,
            }))}
            options={JOB_POSSIBLE_TO_CONFIGURE.map((j) => ({
              key: j,
              label: j,
            }))}
            onChange={
              handleChangeAvailableJobs as (
                optSelected: IOption<string>[],
                name: string,
                uncheckOptions?: IOption<string>[],
                undeterminedOptions?: IOption<string>[]
              ) => void
            }
            multipleSelection={true}
            langlabel={label.inputClientManagementAvailableJobs}
            lang={lang}
          />
          <InputBoolean
            defaultValue={attributes.enable_gfw}
            lang={label.inputDeforestation}
            name="enable_gfw"
            onChange={handleInputChange}
            viewMode={viewMode}
            dataTestId="enable_deforestation"
          />

          <RouteManagement
            client={attributes}
            onChange={handleInputChange}
            viewMode={viewMode}
            onChangeAccessRole={(values: IOption<string>[]) => {
              const formattedValues = _.map(values, (m) => m.key);
              onChangeAttributeValues(
                "route_management_access_roles",
                formattedValues
              );
            }}
          />

          <TerritoriesManagement
            client={attributes}
            onChange={handleInputChange}
            viewMode={viewMode}
            onSelectChange={handleSelectChange}
          />

          <PredictionManagement
            client={attributes}
            onChange={handleInputChange}
            viewMode={viewMode}
          />
          <OrdersManagement
            client={attributes}
            onChange={handleInputChange}
            viewMode={viewMode}
          />
          <VisitsManagement
            client={attributes}
            onChange={handleInputChange}
            viewMode={viewMode}
          />
        </>
      )}
    </FormSection>
  );
};
export default AddonsTab;
